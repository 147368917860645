<script setup>
  import { faInstagram, faTiktok, faFacebook, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();

  useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">

    <section class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-desktop">
            <div class="column is-6 is-offset-3 has-text-centered mt-5">
              <h1 class="subtitle">{{ t('main_message_contact_light_form') }}</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="block has-text-centered">
                <a class="link-gold" :href="'mailto:' + t('company_email_info_bnosport')" :title="t('company_email_info_bnosport')">
                  {{ t('company_email_info_bnosport') }}
                </a>
              </div>
            </div>
          </div>

          <div class="columns is-desktop">
            <div class="column is-6 is-offset-3 has-text-centered mt-5">
              <h1 class="subtitle">{{ t('our_networks') }}</h1>
            </div>
          </div>
          <div class="buttons is-centered">
            <a
                target="_blank"
                aria-label="Twitter"
                class="button is-medium bg-transparent no-border"
                rel="noopener noreferrer"
                href="https://www.instagram.com/benumberonesport/"
            >
              <font-awesome-icon :icon="faInstagram" />
            </a>
            <a
                target="_blank"
                aria-label="Twitter"
                class="button is-medium bg-transparent no-border"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@benumberonesport"
            >
              <font-awesome-icon :icon="faTiktok" />
            </a>
            <a
                target="_blank"
                aria-label="Twitter"
                class="button is-medium bg-transparent no-border"
                rel="noopener noreferrer"
                href="https://www.facebook.com/BeNumberOneSport"
            >
              <font-awesome-icon :icon="faFacebook" />
            </a>
            <a
                target="_blank"
                aria-label="Twitter"
                class="button is-medium bg-transparent no-border"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ"
            >
              <font-awesome-icon :icon="faYoutube" />
            </a>
            <a
                target="_blank"
                aria-label="Twitter"
                class="button is-medium bg-transparent no-border"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/bnosport"
            >
              <font-awesome-icon :icon="faLinkedin" />
            </a>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
