<script setup>
  import { PLAY_STORE_URL, APPLE_IOS_STORE_URL, GOOGLE_FORM } from '@/services/variables.service.js';
  import { useI18n } from "vue-i18n";
  const { t, locale } = useI18n();
</script>

<template>
  <div id="organizers" class="columns pt-5 pb-5">
    <div class="column is-three-fifths is-offset-one-fifth">
      <div class="box content">
        <h3 class="title is-4 has-text-centered">
          <span>{{ t('i_want_organize_competitions') }}</span>
        </h3>
        <p class="feature mx-auto mb-4">
          {{ t('i_want_organize_competitions_message') }}
        </p>
        <div class="block" v-html="t('i_want_organize_competitions_message_options')"></div>
        <div class="buttons is-relative is-centered">
          <a class="button is-bno-green-gradient" :href="GOOGLE_FORM[locale.substring(0, 2)] ?? GOOGLE_FORM['en']" target="_blank" :title="t('lets_go')">
            {{ t('lets_go') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <div class="buttons is-relative is-centered">
        <a class="mr-3" :href="APPLE_IOS_STORE_URL" target="_blank">
          <figure>
            <img :src="require('@/assets/images/app-stores/app-store.svg')" alt="Apple Store" style="max-width: 140px;min-width: 140px;"/>
          </figure>
        </a>
        <a :href="PLAY_STORE_URL" target="_blank">
          <figure>
            <img :src="require('@/assets/images/app-stores/google-play.svg')" alt="Google Play Store" style="max-width: 140px;min-width: 140px;"/>
          </figure>
        </a>
      </div>
    </div>
  </div>
</template>
