<script setup>
  import { Alert } from '@/components';
  import { useRouter } from 'vue-router';
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  const router = useRouter();
</script>

<template>
  <div class="view-wrapper-full">
    <section class="container mb-5 padding-top-100px">
      <Alert
          :title="t('404_error')"
          class-name="is-dark"
          icon-name="exclamation-circle"
          :sub-title="t('not_found')"
      />
    </section>
  </div>
</template>
