import { createApp } from 'vue';
import App from '@/App.vue';

import '@/registerServiceWorker';
import '@/assets/style/main.scss';
import '@/config/fa.config';

import axios from 'axios';
import VueAxios from 'vue-axios'
import i18n from '@/plugins/i18n.js';
import router from '@/router';
import VueGtag from 'vue-gtag-next';
import VueScrollTo from 'vue-scrollto';
import { vClickOutside } from '@/directives';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { META_MANAGER, VUE_GTAG_OPTIONS, VUE_SCROLLTO_OPTIONS } from '@/config';
import moment from 'moment';
import vue3GoogleLogin from 'vue3-google-login'
import { GOOGLE_WEB_CLIENT_ID, RECAPTCHA_KEY } from "@/services/variables.service";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import EventBus from '@/services/event-bus.service';

const app = createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(META_MANAGER)
    .use(VueGtag, VUE_GTAG_OPTIONS)
    .use(VueScrollTo, VUE_SCROLLTO_OPTIONS)
    .use(i18n)
    .use(VueReCaptcha, { siteKey: RECAPTCHA_KEY })
    .directive('click-outside', vClickOutside)
    .component('font-awesome-icon', FontAwesomeIcon);

app.config.globalProperties.$filters = {
    formatDate(value) {
        if (value) {
            if (typeof value === 'string') {
                return moment(String(value)).format('DD/MM/YYYY HH:mm') + 'h';
            }

            return value;
        }
    },
    formatDateDay(value) {
        if (value) {
            if (typeof value === 'string') {
                return moment(String(value)).format('DD/MM/YYYY');
            }

            return value;
        }
    }
}

app.config.globalProperties.$eventBus = EventBus;

app.use(vue3GoogleLogin, {
    clientId: GOOGLE_WEB_CLIENT_ID
})

app.mount('#app');
