<script setup>
import { faInstagram, faTiktok, faFacebook, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<template>
  <footer class="footer">
    <div class="buttons">
      <a
          target="_blank"
          aria-label="Twitter"
          class="button is-medium"
          rel="noopener noreferrer"
          href="https://www.instagram.com/benumberonesport/"
      >
        <font-awesome-icon :icon="faInstagram" />
      </a>
      <a
          target="_blank"
          aria-label="Twitter"
          class="button is-medium"
          rel="noopener noreferrer"
          href="https://www.tiktok.com/@benumberonesport"
      >
        <font-awesome-icon :icon="faTiktok" />
      </a>
      <a
        target="_blank"
        aria-label="Twitter"
        class="button is-medium"
        rel="noopener noreferrer"
        href="https://www.facebook.com/BeNumberOneSport"
      >
        <font-awesome-icon :icon="faFacebook" />
      </a>
      <a
          target="_blank"
          aria-label="Twitter"
          class="button is-medium"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ"
      >
        <font-awesome-icon :icon="faYoutube" />
      </a>
      <a
          target="_blank"
          aria-label="Twitter"
          class="button is-medium"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/bnosport"
      >
        <font-awesome-icon :icon="faLinkedin" />
      </a>
    </div>
    <div class="content has-text-centered">
      <div class="block">
        {{ t('bnosport_sl') }}, {{ t('all_rights_reserved') }}
        <br/>
        Copyright © {{new Date().getFullYear()}}
      </div>
      <div class="block">
        <ul>
          <router-link class="has-text-white mr-4 is-size-7" :to="{name: 'Terms'}">
            <span>{{ t('terms') }}</span>
          </router-link>
          <router-link class="has-text-white is-size-7" :to="{name: 'PrivacyPolicy'}">
            <span>{{ t('privacy_policy') }}</span>
          </router-link>
        </ul>
      </div>
    </div>
  </footer>
</template>
