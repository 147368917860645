<script setup>
  import { API_ENDPOINT } from '@/services/variables.service.js';
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";
  import { CreateCampaignBlock, CampaignCard, CompetitionsSlider } from "@/components";

  import axios from "axios";
  import { useRoute } from "vue-router";
  import { onMounted, ref } from "vue";
  import { toast } from "bulma-toast";

  const route = useRoute();
  const { t, locale } = useI18n();

  const campaignId = route.params.id; // read parameter id (it is reactive)

  useMetaRoute();

  const campaign = ref([]);

  onMounted(async() => {
    await getCampaign();
  });

  async function getCampaign()
  {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    await axios.get(API_ENDPOINT + 'campaigns/' + campaignId, headers).then((response) => {
      campaign.value = response.data.data;
    }).catch(() => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }
</script>

<template>
  <div class="view-wrapper-full bg-color-light padding-top-100px">
    <section class="hero">
      <div class="hero-body pt-0">
        <div class="container">
          <div v-if="campaign" class="columns pb-5">
            <div class="column is-6 is-offset-3">
              <CampaignCard :campaign="campaign" :show-info="true" />
            </div>
          </div>
          <CompetitionsSlider v-if="campaign" :competitions="campaign.competitions" />
          <CreateCampaignBlock />
        </div>
      </div>
    </section>
  </div>
</template>
