<script setup>
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();

  useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">

    <section id="charity" class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="column is-full-mobile">
                <h1 class="title">En el modo outdoor están los siguientes deportes:</h1>
                <div class="block pl-5 pr-5">
                  <div class="feature mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Running</span>
                    <div class="content">
                      <ul>
                        <li>5km</li>
                        <li>10km</li>
                        <li>Media Maratón</li>
                        <li>Maratón</li>
                        <li>Distancias y tiempos configurables</li>
                      </ul>
                    </div>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Marcha</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Trail Running</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Ciclismo</span>
                    <div class="content">
                      <ul type="1">
                        <li>Carretera</li>
                        <li>
                          Montaña
                          <ul type="2">
                            <li>Cross country</li>
                            <li>Descenso</li>
                          </ul>
                        </li>
                        <li>Gravel</li>
                        <li>Handbike</li>
                      </ul>
                    </div>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Natación aguas abiertas</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Ski</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Remo</span>
                  </div>
                </div>
              </div>
              <div class="block pl-5 pr-5">
                <div class="feature mb-5">
                  Todos los deportes pueden ser configurados y adaptados para personas con discapacidad física.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
