<script setup>
  import { defineProps } from 'vue';
  import { useI18n } from "vue-i18n";
  const { t, locale } = useI18n();

  const props = defineProps({
    campaign: Object,
    showInfo: Boolean
  });

</script>

<template>
  <div v-if="campaign" class="card is-clickable border-bottom-radius-25">
    <div class="card-image">
      <figure class="image is-16by9  is-covered">
        <img v-if="campaign?.image?.path" :src="campaign?.image?.path" :alt="campaign?.image?.path">
      </figure>
    </div>
    <div class="card-content">
      <div class="columns is-mobile">
        <div class="column is-full has-text-centered is-12">
          <div v-if="campaign?.name">
            <p class="mb-0">{{ campaign?.name }}</p>
          </div>
        </div>
      </div>
      <div class="columns is-mobile mt-3">
        <div class="column is-6 has-text-centered">
          <p class="m-0">{{ t('from') }}</p>
          <span class="tag">
            {{ $filters.formatDateDay(campaign?.start_date) }}
          </span>
        </div>
        <div class="column is-6 has-text-centered">
          <p class="m-0">{{ t('until') }}</p>
          <span class="tag">
            {{ $filters.formatDateDay(campaign?.end_date) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="campaign && showInfo" class="card is-clickable border-radius-25 mt-5">
    <div class="card-content">
      <div class="columns is-mobile">
        <div class="column is-full has-text-centered is-12">
          <div class="p-4" v-html="campaign?.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>
