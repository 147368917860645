<script setup>
import { useI18n } from "vue-i18n";
import GoogleWebLogin from '@/components/GoogleWebLogin';
import AppleWebLogin from '@/components/AppleWebLogin';
import EmailWebLogin from '@/components/EmailWebLogin';
import { onMounted } from "vue";
import {StorageService} from "@/services/storage.service";
import {useRouter} from "vue-router";

const { t } = useI18n();
let isLogged = StorageService.isLogged();
const router = useRouter();

onMounted(() => {
  var navbar = document.getElementById("navbar");
  navbar.classList.remove('bg-none');

  window.addEventListener("scroll", function (e) {
    navbar.classList.remove('bg-none');
  });

  if (isLogged) {
    router.push('/');
    return false;
  }

})

</script>

<template>
  <div class="view-wrapper-full">

    <section class="hero padding-top-120px">
      <div class="hero-body">
        <div class="container has-text-centered max-width-320px">
          <div class="block">
           <p class="title is-size-5">{{ t('init_login_to_continue') }}</p>
          </div>
          <div class="box p-5 is-size-6">
            <div class="columns mb-4">
              <div class="column">
                <GoogleWebLogin />
              </div>
            </div>
            <!--
            <div class="columns mb-4">
              <div class="column">
                <AppleWebLogin />
              </div>
            </div>
            -->
            <div class="columns">
              <div class="column">
                <EmailWebLogin />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
