<script setup>
import {
  MIN_AMOUNT_DEPOSIT,
  MAX_AMOUNT_DEPOSIT,
  WEB_URL,
  API_USER_ENDPOINT,
  API_USERS_ENDPOINT,
  getSymbolCurrency,
  CURRENT_PAYPAL_CLIENT_ID,
  loadPayPalSdk, loadStripeSdk,
  CURRENT_STRIPE_API_KEY,
  API_ENDPOINT, DEFAULT_AMOUNT_DEPOSIT, BNO_USER_TOKEN_STORAGE
} from '@/services/variables.service.js';

    import { onMounted, ref, computed } from 'vue';
    import { useI18n } from "vue-i18n";
    import { useRoute, useRouter } from "vue-router";
    import axios from "axios";
    import { toast } from "bulma-toast";

    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();

    let dataUser = ref(null);
    let totalWallet = ref(0);
    let loadedPaypal = ref(false);
    let successURL = ref(null);
    let cancelURL = ref(null);
    let showLoading = ref(false);
    let requestDepositedAmount = ref(0);
    let currency = ref('EUR');
    let minDepositAmount = ref(MIN_AMOUNT_DEPOSIT);
    let maxDepositAmount = ref(MAX_AMOUNT_DEPOSIT);
    let disabledBtnStripe = ref(false);
    let token = route.query.token;

    let stripeInstance = ref(null);
    let cardElement = ref(null);

    let disabledPaymentsGateway = ref(null);

    const headers = ref({
      headers: { 'Authorization': 'Bearer ' + token, 'ApiLang': locale.value ?? 'es' }
    });

    const isCorrectAmount = computed(() => requestDepositedAmount.value >= minDepositAmount.value && requestDepositedAmount.value <= maxDepositAmount.value);

    onMounted(async() => {

      if (!token) {
        const bno_user_token = sessionStorage.getItem(BNO_USER_TOKEN_STORAGE);

        if (!bno_user_token) {
          await router.push('/');
          return false;
        }

        token = bno_user_token;
      }

      sessionStorage.setItem(BNO_USER_TOKEN_STORAGE, token);

      successURL.value = WEB_URL + '/' + route.path + '/success';
      cancelURL.value = WEB_URL + '/' + route.path + '/error';

      requestDepositedAmount.value = DEFAULT_AMOUNT_DEPOSIT;

      await loadData();
    });

    async function loadData()
    {
      axios.get(API_USER_ENDPOINT, headers.value).then((response) => {
        dataUser.value = response.data.data;

        loadPaypalJS();
        loadStripeJS();

        axios.get(API_USERS_ENDPOINT + '/' + dataUser.value.id + '/wallet', headers.value).then((response) => {
          const wallet = response.data.data;
          totalWallet.value = wallet.amount;

          setTimeout(() => {
            document.getElementById('username').scrollIntoView({
              behavior: "smooth"
            });
          }, 1000);
        })
        .catch((error) => {
          const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');

          toast({
            message: message,
            type: 'is-danger',
            dismissible: true,
            duration: 5000
          });

          router.push('/');
        });
      })
      .catch((error) => {
        const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');

        toast({
          message: message,
          type: 'is-danger',
          dismissible: true,
          duration: 5000
        });

        router.push('/');
      });
    }


    function loadPaypalJS()
    {
      const amount = parseFloat(requestDepositedAmount.value).toFixed(2).toString();
      const userId = dataUser.value.id;
      const locale = (dataUser.value.lang === 'es') ? 'es_ES' : 'en_US';

      loadPayPalSdk({
        'client-id': CURRENT_PAYPAL_CLIENT_ID,
        intent: 'capture',
        currency: currency.value,
        'enable-funding': 'credit',
        'disable-funding': 'card',
        locale: locale,
      }).then((paypal) => {
        paypal.Buttons({
          style: {
            layout:  'horizontal',
            color:   'gold',
            shape:   'pill',
            label:   'paypal',
            size:    'responsive',
            tagline: true
          },
          createOrder(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency.value,
                    value: amount
                  },
                  custom_id: userId,
                  payment_instruction: {
                    disbursement_mode: 'INSTANT',
                  },
                  description: t('deposit_message_invoice'),
                }
              ]
            });
          },
          onApprove(data, actions) {
            return actions.order.capture().then(() => {
              toast({
                message: t('deposit_success', { amount: requestDepositedAmount.value, currency: getSymbolCurrency(currency.value)} ),
                type: 'is-success',
                dismissible: true,
                duration: 3000
              });
            });
          },
          onCancel() {
            toast({
              message: t('deposit_cancelled'),
              type: 'is-danger',
              dismissible: true,
              duration: 3000
            });
          },
          onError() {
            toast({
              message: t('deposit_error'),
              type: 'is-danger',
              dismissible: true,
              duration: 3000
            });
          }
        }).render('#paypal-button').then(() => {
          loadedPaypal.value = true;
        });
      });
    }

    function loadStripeJS()
    {
      loadStripeSdk().then(() => {
        stripeInstance.value = Stripe(CURRENT_STRIPE_API_KEY.pk, { locale: dataUser.value.lang });
        const elements = stripeInstance.value.elements({
          fonts: [
            {
              family: 'Roboto Slab',
              src: 'url("https://fonts.gstatic.com/s/robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmYmRjV9Ku1e6i.woff2")',
              weight: '400',
            }
          ]
        });

        cardElement.value = elements.create('card', {
          style: {
            base: {
              fontWeight: 500,
              fontFamily: 'Roboto, serif',
              fontSize: '16px',
            },
            invalid: {
              iconColor: '#f04141',
              color: '#f04141',
            },
          },
          hidePostalCode: true
        });

        cardElement.value.mount('#card-element');

        const cardErrors = document.getElementById('card-errors');

        cardElement.value.on('change', (event) => {
          if (event.complete) {
            cardErrors.textContent = '';
            disabledBtnStripe.value = false;
          } else if (event.error) {
            cardErrors.textContent = event.error.message;
            disabledBtnStripe.value = true;
          }
        });
      });
    }

    function executeDepositStripe(event)
    {
      event.preventDefault();

      if (!isCorrectAmount.value) {
        return false;
      }

      const paymentMethodData = {
        type: 'card',
        card: cardElement.value,
        billing_details: {
          name: dataUser.value.name,
          email: dataUser.value.email,
        }
      };

      disabledBtnStripe.value = true;
      stripeInstance.value.createPaymentMethod(paymentMethodData).then((result) => {
        if (result.error) {
          disabledBtnStripe.value = false;
          toast({
            message: result.error.message,
            type: 'is-danger',
            dismissible: true,
            duration: 3000
          });
        } else {

          const data = {
            payment_method_id: result.paymentMethod.id,
            user_id: dataUser.value.id,
            amount: parseFloat(requestDepositedAmount.value)
          };

          axios.post(API_ENDPOINT + 'stripe-payment-intent', data, headers.value).then((response) => {
            if (response) {
              handleServerResponseStripe(response.data.data);
            }
          })
          .catch((error) => {
            disabledBtnStripe.value = false;
            toast({
              message: error.message,
              type: 'is-danger',
              dismissible: true,
              duration: 3000
            });
          });
        }
      });
    }

    function handleServerResponseStripe(response)
    {
      if (response.error) {
        disabledBtnStripe.value = false;
        toast({
          message: response.error_message,
          type: 'is-danger',
          dismissible: true,
          duration: 3000
        });
      } else {
        if (response.payment_intent.requires_action) {
          confirmCardPaymentStripe(response);
        } else {
          disabledBtnStripe.value = false;

          toast({
            message: t('deposit_success', {
              amount: requestDepositedAmount.value,
              currency: getSymbolCurrency(currency.value)
            }),
            type: 'is-success',
            dismissible: true,
            duration: 7000
          });

          cardElement.value.clear();
        }
      }
    }

    function confirmCardPaymentStripe(response)
    {
      stripeInstance.value.confirmCardPayment(
          response.payment_intent.payment_intent_client_secret,
          {
            payment_method: {
              card: cardElement.value,
              billing_details: {
                name: dataUser.value.name,
                email: dataUser.value.email,
              }
            },
            receipt_email: dataUser.value.email,
          }
      ).then((result) => {
        if (result.error) {
          disabledBtnStripe.value = false;

          toast({
            message: response.error_message,
            type: 'is-danger',
            dismissible: true,
            duration: 3000
          });

        } else {
          disabledBtnStripe.value = false;

          toast({
            message: t('deposit_success', {
              amount: requestDepositedAmount.value,
              currency: getSymbolCurrency(currency.value)
            }),
            type: 'is-success',
            dismissible: true,
            duration: 7000
          });

          cardElement.value.clear();
        }
      });
    }

    function setRequestAmount()
    {
      loadedPaypal.value = false;
      document.getElementById('paypal-button').innerHTML = "";
      loadPaypalJS();
    }

</script>

<template>
  <div class="view-wrapper-full">

    <section id="username" class="hero bg-color-light padding-top-100px">
      <div class="columns">
        <div class="column is-three-fifths is-offset-one-fifth has-text-centered">
          <div class="block">
            <div class="subtitle" v-html="t('add_funds')"></div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div v-if="dataUser?.username" class="block has-text-centered">
            <p class="tag has-background-green is-medium has-text-white">
              {{ t('hello' ) }} {{ dataUser?.username }}
            </p>
          </div>
          <div class="columns pb-5">
            <div class="column is-6 is-offset-3">

              <div class="card border-radius-25">
                <div class="card-content">
                  <div class="columns has-text-centered is-mobile">
                    <div class="column pb-0">
                      <h4 class="has-text-left">{{ t('select_amount') }}</h4>
                    </div>
                  </div>

                  <div class="columns has-text-centered is-mobile">
                    <div class="column is-12">
                      <div class="block has-text-centered mt-4">
                        <input
                            class="input is-large"
                            id="requestDepositedAmount"
                            v-model="requestDepositedAmount"
                            type="number"
                            :min="minDepositAmount"
                            :max="maxDepositAmount"
                            :placeholder="t('enter_amount')"
                            aria-describedby="input-amount"
                            :required="true"
                            @blur="setRequestAmount()"
                            step="1">
                      </div>
                    </div>
                  </div>

                  <div class="columns has-text-centered is-mobile">
                    <div class="column is-6">
                      Min: {{ minDepositAmount }}{{getSymbolCurrency(currency)}}
                    </div>
                    <div class="column is-6">
                      Max: {{ maxDepositAmount }}{{getSymbolCurrency(currency)}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div v-if="!disabledPaymentsGateway?.stripe" class="columns pb-5">
            <div class="column is-6 is-offset-3">
              <div class="card border-radius-25">
                <div class="card-content">
                  <div class="columns has-text-centered is-mobile">
                    <div class="column pb-0">
                      <h4 class="has-text-left">{{ t('card') }}</h4>
                    </div>
                  </div>
                  <div class="columns has-text-centered is-mobile">
                    <div class="column is-12" :class="!isCorrectAmount ? 'disabled-content' : ''">
                      <div class="block has-text-centered mt-4">
                        <div id="stripe_container" class="col-12">
                          <form>
                            <div id="card-element" class="full-width bg-light p-4"></div>
                            <div id="card-errors" class="full-width has-text-danger mt-2"></div>
                            <div class="full-width mt-4 mb-4" v-html="t('message_deposit_stripe')"></div>
                            <button class="button btn-paypal mt-4"
                                    :class="disabledBtnStripe ? 'is-loading' : ''"
                                    :disabled="disabledBtnStripe"
                                    @click="executeDepositStripe($event)">
                              {{ t('add_funds') }}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div v-if="!disabledPaymentsGateway?.paypal" class="columns pb-5">
            <div class="column is-6 is-offset-3">

              <div class="card border-radius-25">
                <div class="card-content">
                  <div class="columns has-text-centered is-mobile">
                    <div class="column pb-0">
                      <h4 class="has-text-left">{{ t('paypal') }}</h4>
                    </div>
                  </div>

                  <div class="columns has-text-centered is-mobile">
                    <div class="column is-12" :class="!isCorrectAmount ? 'disabled-content' : ''">
                      <div class="block has-text-centered mt-4">
                        <div id="paypal_container" class="col-12">
                          <div v-if="!loadedPaypal" class="text-center">
                            <p class="ion-no-margin">
                              {{ t('loading_paypal') }}
                            </p>
                          </div>
                          <div id="paypal-button" :style="(loadedPaypal) ? 'display: inherit;' : 'display:none;'"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<style>
#requestDepositedAmount {
  text-align: center;
  font-family: 'Roboto Slab', serif;
}

#card-element {
  border:1px solid #ffc439;
  font-family: 'Roboto Slab', serif;
}
</style>
