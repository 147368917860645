<script setup>
import { defineProps, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const props = defineProps({
  currentAction: Object,
  appName: String
});

onMounted(async() => {
  setTimeout(() => {
    document.getElementById('social_app').scrollIntoView({
      behavior: "smooth"
    });
  }, 1000);
});

</script>
<template>
  <div class="view-wrapper-full">
    <section id="social_app" class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-align-items-center is-desktop">
            <div class="column has-text-centered">
              <h1 class="title">{{ appName?.toUpperCase() }}</h1>
              <p v-if="currentAction">{{ currentAction }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="block has-text-centered">
                <figure>
                  <img :src="require('@/assets/images/' + appName + '.png')" width="150" :alt="appName" style="width: 150px !important;"/>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
