<script setup>
  import { ref, onMounted } from 'vue';
  import { useI18n } from "vue-i18n";
  import CompetitionCard from '@/components/CompetitionCard.vue';

  const { t, locale } = useI18n();
  const campaigns = ref([]);

  const props = defineProps({
    competitions: Object
  });

</script>

<template>
  <div v-if="competitions" class="columns pt-5 pb-5">
    <div class="column is-8 is-offset-2 mt-5">
      <h1 class="title has-text-centered has-text-bno-black">
        {{ t('competitions')}}
      </h1>
      <hr class="has-350px-width ml-auto mr-auto"/>
      <div id="featured-competitions" class="slider carousel-competitions">
        <div class="slider-container is-align-items-center">
          <div class="p-2" :class="'slider-item item-' + index" v-for="(competition, index) of competitions" :key="index">
            <router-link :to="{ name: 'Competition', params:{id: competition._id} }">
              <CompetitionCard :competition="competition" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import 'bulma-carousel/dist/css/bulma-carousel.min.css';

.slider-item {
  max-width: 360px;
}
</style>
