import i18n from '@/plugins/i18n.js';
import { WEB_URL } from "@/services/variables.service";

const { t } = i18n.global;

export const HOME_PAGE_META = {
    transition: 'fade',
    title: t('metas.title'),
    description: t('metas.description'),
    href_canonical: WEB_URL,
    og: {
        url: WEB_URL,
        title: t('metas.title'),
        description: t('metas.description')
    },
    twitter: {
        title: t('metas.title'),
        description: t('metas.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const COMPETITIONS_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.competitions.title'),
    description: t('metas.pages.competitions.description'),
    href_canonical: WEB_URL + 'competitions',
    og: {
        url: WEB_URL + 'competitions',
        title: t('metas.pages.competitions.title'),
        description: t('metas.pages.competitions.description')
    },
    twitter: {
        title: t('metas.pages.competitions.title'),
        description: t('metas.pages.competitions.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Event",
            "@id" : WEB_URL + 'competitions',
            "name":"Competiciones activas",
            "url": WEB_URL + 'competitions',
            "description": t('metas.pages.competitions.description'),
            "keywords": t('metas.pages.competitions.keywords')
        }
    }]
};

export const MONETIZE_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.monetize.title'),
    description: t('metas.pages.monetize.description'),
    href_canonical: WEB_URL + 'monetize',
    og: {
        url: WEB_URL + 'monetize',
        title: t('metas.pages.monetize.title'),
        description: t('metas.pages.monetize.description')
    },
    twitter: {
        title: t('metas.pages.monetize.title'),
        description: t('metas.pages.monetize.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const APPS_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.apps.title'),
    description: t('metas.pages.apps.description'),
    href_canonical: WEB_URL + 'apps',
    og: {
        url: WEB_URL + 'apps',
        title: t('metas.pages.apps.title'),
        description: t('metas.pages.apps.description')
    },
    twitter: {
        title: t('metas.pages.apps.title'),
        description: t('metas.pages.apps.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const CONTACT_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.contact.title'),
    description: t('metas.pages.contact.description'),
    href_canonical: WEB_URL + 'contact',
    og: {
        url: WEB_URL + 'contact',
        title: t('metas.pages.contact.title'),
        description: t('metas.pages.contact.description')
    },
    twitter: {
        title: t('metas.pages.contact.title'),
        description: t('metas.pages.contact.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"ContactPage",
            "name":"Contacto",
            "description": "Si tienes alguna consulta, duda o incidencia, puedes ponerte en contacto con nosotros a través de la dirección de correo info@bnosport.com",
            "url": WEB_URL + 'contact'
        }
    }]
};

export const TERMS_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.terms.title'),
    description: t('metas.pages.terms.description'),
    href_canonical: WEB_URL + 'terms',
    og: {
        url: WEB_URL + 'terms',
        title: t('metas.pages.terms.title'),
        description: t('metas.pages.terms.description')
    },
    twitter: {
        title: t('metas.pages.terms.title'),
        description: t('metas.pages.terms.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const PRIVACY_POLICY_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.privacy-policy.title'),
    description: t('metas.pages.privacy-policy.description'),
    href_canonical: WEB_URL + 'privacy-policy',
    og: {
        url: WEB_URL + 'privacy-policy',
        title: t('metas.pages.privacy-policy.title'),
        description: t('metas.pages.privacy-policy.description')
    },
    twitter: {
        title: t('metas.pages.privacy-policy.title'),
        description: t('metas.pages.privacy-policy.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const PLANS_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.plans.title'),
    description: t('metas.pages.plans.description'),
    href_canonical: WEB_URL + 'plans',
    og: {
        url: WEB_URL + 'plans',
        title: t('metas.pages.plans.title'),
        description: t('metas.pages.plans.description')
    },
    twitter: {
        title: t('metas.pages.plans.title'),
        description: t('metas.pages.plans.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Organization",
            "name":"Be Number One Sport",
            "alternateName": [
                "benumberonesport",
                "bnosport"
            ],
            "legalName": "Be Number One Sport SL",
            "url":"https://benumberonesport.com",
            "logo": "https://static.bnosport.com/logos/512.png",
            "sameAs": [
                "https://www.instagram.com/benumberonesport/",
                "https://www.facebook.com/BeNumberOneSport",
                "https://www.tiktok.com/@benumberonesport",
                "https://www.linkedin.com/company/bnosport",
                "https://www.youtube.com/channel/UCnVItt0GRa0FzF64zHZmGnQ",
                "https://play.google.com/store/apps/details?id=com.benumberonesport.simpleapp.prod",
                "https://apps.apple.com/es/app/be-number-one-sport/id1563255668#?platform=iphone"
            ]
        }
    }]
};

export const CAMPAIGNS_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.campaigns.title'),
    description: t('metas.pages.campaigns.description'),
    href_canonical: WEB_URL + 'campaigns',
    og: {
        url: WEB_URL + 'campaigns',
        title: t('metas.pages.campaigns.title'),
        description: t('metas.pages.campaigns.description')
    },
    twitter: {
        title: t('metas.pages.campaigns.title'),
        description: t('metas.pages.campaigns.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Event",
            "@id" : WEB_URL + 'campaigns',
            "name":"Campañas deportivas con competiciones activas",
            "url": WEB_URL + 'campaigns',
            "description": t('metas.pages.campaigns.description'),
            "keywords": t('metas.pages.campaigns.keywords')
        }
    }]
};

export const CREATE_CAMPAIGN_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.create-campaign.title'),
    description: t('metas.pages.create-campaign.description'),
    href_canonical: WEB_URL + 'create-campaign',
    og: {
        url: WEB_URL + 'create-campaign',
        title: t('metas.pages.create-campaign.title'),
        description: t('metas.pages.create-campaign.description')
    },
    twitter: {
        title: t('metas.pages.create-campaign.title'),
        description: t('metas.pages.create-campaign.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Event",
            "@id" : WEB_URL + 'create-campaign',
            "name":"Crear campaña con varias competiciones",
            "url": WEB_URL + 'create-campaign',
            "description": t('metas.pages.create-campaign.description'),
            "keywords": t('metas.pages.create-campaign.keywords')
        }
    }]
};


export const INDOOR_MODE_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.indoor.title'),
    description: t('metas.pages.indoor.description'),
    href_canonical: WEB_URL + 'indoor',
    og: {
        url: WEB_URL + 'indoor',
        title: t('metas.pages.indoor.title'),
        description: t('metas.pages.indoor.description')
    },
    twitter: {
        title: t('metas.pages.indoor.title'),
        description: t('metas.pages.indoor.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Event",
            "@id" : WEB_URL + 'indoor',
            "name":"Modo de competición indoor, con descripciones de los deportes activos y competiciones de modo indoor",
            "url": WEB_URL + 'indoor',
            "description": t('metas.pages.indoor.description'),
            "keywords": t('metas.pages.indoor.keywords')
        }
    }]
};

export const OUTDOOR_MODE_PAGE_META = {
    transition: 'fade',
    title: t('metas.pages.outdoor.title'),
    description: t('metas.pages.outdoor.description'),
    href_canonical: WEB_URL + 'create-campaign',
    og: {
        url: WEB_URL + 'outoor',
        title: t('metas.pages.outdoor.title'),
        description: t('metas.pages.outdoor.description')
    },
    twitter: {
        title: t('metas.pages.outdoor.title'),
        description: t('metas.pages.outdoor.description')
    },
    script: [{
        type: 'application/ld+json',
        json: {
            '@context': 'https://schema.org',
            "@type":"Event",
            "@id" : WEB_URL + 'outdoor',
            "name":"Modo de competición outdoor, con descripciones de los deportes activos y competiciones de modo outdoor",
            "url": WEB_URL + 'outdoor',
            "description": t('metas.pages.outdoor.description'),
            "keywords": t('metas.pages.outdoor.keywords')
        }
    }]
};
