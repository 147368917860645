<script setup>
import { useMetaRoute } from '@/composables';
import { useI18n } from "vue-i18n";
import CampaignSlider from '@/components/CampaignSlider.vue';

const { t } = useI18n();


useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero bg-color-light padding-top-100px">
      <CampaignSlider />
    </section>
  </div>
</template>
<style>
@import 'bulma-carousel/dist/css/bulma-carousel.min.css';

.slider-item {
  max-width: 360px;
}
</style>
