<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import { Menu } from '@/components';
import axios from 'axios';
import bulmaCarousel from 'bulma-carousel';
import { toast } from "bulma-toast";
import { API_PLANS_ENDPOINT, API_USER_ENDPOINT, API_USERS_ENDPOINT, getSymbolCurrency } from '@/services/variables.service.js';
import { useRoute } from "vue-router";

const route = useRoute();
const { t, locale } = useI18n();

const plans = ref([]);
const dataUser = ref({});
const totalWallet = ref(0);
const selectedPlan = ref({});

const token = route.query.token; // read parameter id (it is reactive)

const headers = ref({
  headers: { 'Authorization': 'Bearer ' + token, 'ApiLang': locale.value }
});

if (bulmaCarousel) {
  bulmaCarousel.attach('#carousel-campaigns', {
    slidesToScroll: 1,
    slidesToShow: 1,
    loop: true,
    infinite: false
  });
}

onMounted(async() => {
  await getPlans();

  if (token) {
    await getUser();
  }
});

async function getPlans()
{
  await axios.get(API_PLANS_ENDPOINT, headers).then((response) => {
    const plansArray = [];
    const p = response.data.data;

    for (const i in p) {
      if (p[i].is_public) {
        plansArray.push(p[i]);
      }
    }

    plans.value = plansArray;
  })
  .catch(() => {
    toast({
      message: t('error'),
      type: 'is-danger',
      dismissible: true,
      duration: 3000
    })
  });
}

async function getUser()
{
  await axios.get(API_USER_ENDPOINT, headers).then((response) => {
    const user = response.data.data;
    dataUser.value = user;

    axios.get(API_USERS_ENDPOINT + '/' + user.id + '/wallet', headers).then((response) => {
      totalWallet.value = response.data.data.amount;
    }).catch(() => {});
  })
  .catch((error) => {});
}

function makePremium() {
  if (dataUser.plan_id === null) {
    if (totalWallet < selectedPlan.price) {
      toast({
        message: t('not_enough_funds'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    } else {
      $refs['buyPlan'].show();
    }
  } else {
    toast({
      message: t('already_premium'),
      type: 'is-danger',
      dismissible: true,
      duration: 3000
    })
  }
}

async function postBuyPlan()
{
  axios.post(API_PLANS_ENDPOINT + '/' + selectedPlan.id + '/users/' + dataUser.id, {}, headers).then((response) => {
    this.$refs['buyPlan'].hide();
    if (response.data.hasOwnProperty('message')) {
      getUser();
    }
    toast({
      message: t('success'),
      type: 'is-success',
      dismissible: true,
      duration: 3000
    })
  })
  .catch((error) => {
    toast({
      message: t('error'),
      type: 'is-danger',
      dismissible: true,
      duration: 3000
    })
  });
}

</script>
<template>
  <div class="view-wrapper-full">

    <section class="hero is-dark padding-top-120px">
        <div class="hero-body">
          <div class="container has-text-centered">
             <div class="is-flex is-horizontal-center">
              <figure class="image is-132x132 bg-white border-radius-8">
                <img class="img-fluid" src="@/assets/images/logos/bnosport/logo-sports.svg" alt="Logo BNOSport">
              </figure>
            </div>
            <div class="block mt-4">
              <p class="title m-0">{{ t('make_premium') }}</p>
            </div>
            <hr>
            <div class="columns">
              <div class="column is-three-fifths is-offset-one-fifth">
                <div class="block">
                  <p class="subtitle">{{ t('plans') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section class="hero" :class="index % 2 !== 0 ? 'bg-color-light' : ''" v-for="(plan, index) of plans" :key="index">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-align-items-center is-desktop">
            <div class="column has-text-centered">
              <h1 class="title">{{ plan?.name }}</h1>
              <h2 class="subtitle">{{ plan?.price + ' ' + getSymbolCurrency(plan?.currency) }}</h2>
              <p><small>{{ t('valid_until') }}: {{ $filters.formatDate(plan?.valid_until) }}</small></p>
            </div>
          </div>
          <div class="columns is-mobile">

            <div class="column is-6 is-offset-3">
              <div class="columns is-mobile">
                <div class="column" :class="plan?.image?.path ? 'is-4' : 'is-hidden'">
                   <figure class="image is-16by9  is-covered">
                      <img v-if="plan?.image?.path" :src="plan?.image?.path" :alt="plan?.image?.path">
                    </figure>
                </div>
                <div class="column" :class="plan?.image?.path ? 'is-8' : 'is-12'">
                  <div class="content">
                    <ul type="1">
                      <li v-for="(feature, key) of plan?.features?.data" :key="key">
                        <h4 class="m-0 font-weight-bold">
                          {{ feature?.translated_name }}
                        </h4>
                        <div class="mt-2" v-html="feature?.translated_description"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
