import { useRoute } from 'vue-router';
import { useMeta, type MetaSourceProxy } from 'vue-meta';

export default function useMetaRoute(): MetaSourceProxy {
  const route = useRoute();
  const { title, description, twitter, script } = route?.meta ?? {};
  const canonical = route?.meta?.href_canonical ?? window?.location.href ?? 'unknown';

  const { meta } = useMeta({
    title,
    description,
    link: {
      rel: 'canonical',
      href: canonical
    },
    og: {
      canonical,
      title,
      description
    },
    twitter,
    htmlAttrs: {
      lang: 'es',
      amp: false
    },
    script
  });

  return meta;
}
