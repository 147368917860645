let api_endpoint = 'https://api.bno.pbas.es/v1/';
let api_endpoint_v2 = 'https://api.bno.pbas.es/v2/';
let url_cdn_api = 'https://static.bno.pbas.es/';
let web_url = 'https://web.bno.pbas.es/';

let stripe_keys = {
    pk: 'pk_test_51INQqvJeGjS2693iZvmdR3mScetWpdsKUtOyW2jgsEgJrvI4A8K8nOSvgr6m6sAmamYw8JT3I3uQWiJjJ1cbTyAb00xUVz3K0f',
    sk: 'sk_test_51INQqvJeGjS2693i9dHlHPGeux7J01SoUyqisR1qWqkqLLCfmq1mAvAJZktds1a8cAFtuWf4mOBbThEZsr959dI500dcztWwtK',
};

if(process.env.NODE_ENV === 'production') {
    api_endpoint = 'https://api.bnosport.com/v1/';
    api_endpoint_v2 = 'https://api.bnosport.com/v2/';
    url_cdn_api = 'https://static.bnosport.com/';
    web_url = 'https://benumberonesport.com/';
    stripe_keys = {
        pk: 'pk_live_51INQqvJeGjS2693iZrdSvQLnGCL2GmBbiBkMTtlXgUCksyfBO2nS02Ynbmmx09eKC1Zdb4SRO8Rw71lDzwPDD6gn00CsnomhaR',
        sk: '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',
    };
}

export const API_ENDPOINT = api_endpoint;
export const API_ENDPOINT_V2 = api_endpoint_v2;
export const API_COMPETITIONS_CAMPAIGNS_ENDPOINT = API_ENDPOINT + 'campaigns';
export const API_PLANS_ENDPOINT = API_ENDPOINT + 'plans';
export const API_APPS_ENDPOINT = API_ENDPOINT + 'apps';
export const API_APPS_ENDPOINT_V2 = API_ENDPOINT_V2 + 'apps';
export const API_USER_ENDPOINT = API_ENDPOINT + 'user';
export const API_USERS_ENDPOINT = API_ENDPOINT + 'users';
export const WEB_URL = web_url;

export const HUAWEI_APP_GALLERY_ANDROID_STORE_URL = 'https://appgallery.huawei.com/#/app/C104247713';
export const AMAZON_APP_ANDROID_STORE_URL = 'https://www.amazon.com/gp/product/B0938W8WQT';
export const APPLE_IOS_STORE_URL = 'https://apps.apple.com/es/app/be-number-one-sport/id1563255668?platform=iphone';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.benumberonesport.prod';

export const API_PAYPAL_CLIENT_ID_SANDBOX = 'Aah1e7xB2oG-cm8znn4tZtQ6Gl-wngRVVwwGGjyTqDyFMXV4vCG281xoeMv_oMypU-gxlHC8E-_P-7P-';
export const API_PAYPAL_CLIENT_ID_PRODUCTION = 'Aa6RcQ2N3s-3BK6Y1mjJWxhZ82UYAC6Dhaq8pKi8vg9d7VCI4__iiM0YrkNi_kzuCPkEZK9fgzeiw4zE';
export const CURRENT_PAYPAL_CLIENT_ID = (process.env.NODE_ENV !== 'production') ? API_PAYPAL_CLIENT_ID_SANDBOX : API_PAYPAL_CLIENT_ID_PRODUCTION;
export const CURRENT_STRIPE_API_KEY = stripe_keys;

export const MIN_AMOUNT_DEPOSIT = 1;
export const DEFAULT_AMOUNT_DEPOSIT = 5;
export const MAX_AMOUNT_DEPOSIT = 100;

export const GOOGLE_WEB_CLIENT_ID = '872819341295-93rqsafn6mc9i73frii2o943g42alctc.apps.googleusercontent.com';
export const BNO_USER_TOKEN_STORAGE = 'bno_user_token';
export const RECAPTCHA_KEY = '6LcxeG8jAAAAAKyiU5XwdIIycYxiFnNgPWQAlFsi';
export const RECAPTCHA_SECRET = '6LcxeG8jAAAAAKWaK5vCo8bNDru708pjd6kz9M7L';
export const GOOGLE_FORM = {
    'es-ES': 'https://forms.gle/bC7DReFQRRQ4fbx16',
    'es': 'https://forms.gle/bC7DReFQRRQ4fbx16',
    'en': 'https://forms.gle/EZczsMd74y3dDPDk7'
};

const PAYPAL_JS_SDK = 'https://www.paypal.com/sdk/js?';
const STRIPE_JS_SDK = 'https://js.stripe.com/v3/';
const APPLE_AUTH_JS_SDK = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/es_ES/appleid.auth.js';
const APPLE_AUTH_CLIENT_ID = 'com.benumberonesport.web.login';

export function getUrlAppleAuth()
{
    const redirect_url = WEB_URL + 'login';
    return 'https://appleid.apple.com/auth/authorize?client_id=' + APPLE_AUTH_CLIENT_ID + '&redirect_uri=' + redirect_url + '&response_type=code%20id_token&state=' + (new Date()).getTime().toString() + '&scope=name%20email&response_mode=query';
}

export function getSymbolCurrency(currency)
{
    return (currency.toLowerCase() === 'dolar') ? '$':  '€';
}

export function capitalize(s)
{
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function stripHtmlTags(html)
{
    return html.replace(/<[^>]*>?/gm, '');
}

export function isDate(date)
{
    return !isNaN(Date.parse(date))
}

export function getPayPal()
{
    return window && window.paypal;
}

export function loadPayPalSdk(config)
{
    const payPal = getPayPal();

    return payPal ? Promise.resolve( payPal ) : new Promise( (resolve, reject) => {

        try {
            const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${PAYPAL_JS_SDK}"]`);
            const injectScript = () => {

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const script = document.createElement('script');

                script.src = payPalSdkUrl(config);
                script.type = 'text/javascript';
                script.defer = true;
                script.async = true;

                (document.head || document.body).appendChild(script);

                return script;
            };

            const script = findScript() || injectScript();

            script.addEventListener('error', (error) => {
                reject( new Error('Unable to load PayPal SDK') );
            });
            script.addEventListener('load', () => resolve( getPayPal() ));
        } catch (e) { reject(e); }
    });
}

export function payPalSdkUrl(config) {

    const hyphenize = (camel) => {
        return camel.replace(/([A-Z])/g, $1 => {
            return '-' + $1.toLowerCase();
        });
    };

    return Object.keys(config).reduce( (url, token, idx) => {
        return url + (idx && '&' || '') + hyphenize(token) + '=' + config[token];
    }, PAYPAL_JS_SDK);
}


export function getStripe()
{
    return window && window.Stripe;
}

export function loadStripeSdk()
{
    const stripe = getStripe();

    return stripe ? Promise.resolve( stripe ) : new Promise( (resolve, reject) => {

        try {
            const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${STRIPE_JS_SDK}"]`);
            const injectScript = () => {

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const script = document.createElement('script');

                script.src = STRIPE_JS_SDK;
                script.type = 'text/javascript';
                script.defer = true;
                script.async = true;

                (document.head || document.body).appendChild(script);

                return script;
            };

            const script = findScript() || injectScript();

            script.addEventListener('error', (error) => {
                reject( new Error('Unable to load Stripe SDK') );
            });
            script.addEventListener('load', () => resolve( getStripe() ));
        } catch (e) { reject(e); }
    });
}

export function getAppleAuth()
{
    return window && window.AppleID;
}

export function loadAppleAuth()
{
    const appleAuth = getAppleAuth();

    return appleAuth ? Promise.resolve( appleAuth ) : new Promise( (resolve, reject) => {

        try {
            const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${APPLE_AUTH_JS_SDK}"]`);
            const injectScript = () => {

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const script = document.createElement('script');

                script.src = APPLE_AUTH_JS_SDK;
                script.type = 'text/javascript';
                script.defer = true;
                script.async = true;

                (document.head || document.body).appendChild(script);

                return script;
            };

            const script = findScript() || injectScript();

            script.addEventListener('error', (error) => {
                reject( new Error('Unable to load Apple Auth SDK') );
            });
            script.addEventListener('load', () => resolve( getAppleAuth() ));
        } catch (e) { reject(e); }
    });
}


export function initAppleAuth()
{
    if (window && window.hasOwnProperty('AppleID')) {
        /*
        window.AppleID.auth.init({
            clientId : APPLE_AUTH_CLIENT_ID,
            scope : 'name email',
            redirectURI : WEB_URL,
            state : (new Date()).getTime().toString(),
            nonce : (new Date()).getTime().toString(),
            usePopup : true
        });
         */
    }
}

export function isUrl(string)
{
    let isUrl = false;
    try {
        new URL(string);
        isUrl = true;
    } catch (_) {}

    return isUrl;
}
