<script setup>
import {
  PLAY_STORE_URL,
  APPLE_IOS_STORE_URL,
  HUAWEI_APP_GALLERY_ANDROID_STORE_URL,
  AMAZON_APP_ANDROID_STORE_URL
} from '@/services/variables.service.js';
import { useMetaRoute, useTheme } from '@/composables';
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { themeCls } = useTheme();

useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">

    <section id="ios" class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-align-items-center is-desktop">
            <div class="column has-text-centered mt-5">
              <h1 class="title">{{ t('apple') }}</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="block has-text-centered">
                <a :href="APPLE_IOS_STORE_URL" target="_blank">
                  <figure>
                    <img :src="require('@/assets/images/app-stores/app-store.svg')" alt="Apple Store" style="max-width: 140px;min-width: 140px;"/>
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="android" :class="['container dashboard-content', themeCls]">
      <div class="columns mb-0 is-align-items-center is-desktop">
        <div class="column">
          <h1 class="title">{{ t('android') }}</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column pt-0 is-8 is-offset-2 has-text-left">
          <div class="block has-text-centered">
            <a :href="PLAY_STORE_URL" target="_blank">
              <figure>
                <img :src="require('@/assets/images/app-stores/google-play.svg')" alt="Play Store" style="max-width: 140px;min-width: 140px;"/>
              </figure>
            </a>
          </div>
          <hr/>
          <div class="columns is-mobile mt-5">
            <div class="column is-6">
              <div class="block has-text-centered">
                <a :href="HUAWEI_APP_GALLERY_ANDROID_STORE_URL" target="_blank">
                  <figure>
                    <img :src="require('@/assets/images/app-stores/appgallery.png')" alt="Huawei App Gallery" style="max-width: 140px;min-width: 140px;"/>
                  </figure>
                </a>
              </div>
            </div>
            <div class="column is-6">
              <div class="block has-text-centered">
                <a :href="AMAZON_APP_ANDROID_STORE_URL" target="_blank">
                  <figure>
                    <img :src="require('@/assets/images/app-stores/amazon.png')" alt="Amazon App Store" style="max-width: 140px;min-width: 140px;"/>
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
