<script setup>
  import { SocialApp } from '@/components';
  import {
    API_APPS_ENDPOINT_V2,
    API_ENDPOINT_V2,
    BNO_USER_TOKEN_STORAGE
  } from '@/services/variables.service.js';
  import { useI18n } from "vue-i18n";
  import { useRoute } from "vue-router";
  import axios from "axios";
  import { toast } from "bulma-toast";
  import { ref } from "vue";

  const { t, locale } = useI18n();
  const route = useRoute();
  const currentAction = ref(null);
  const action = route.query.action; // read parameter id (it is reactive)
  let state = route.query.state;
  let code = route.query.code;
  let token = route.query.token;

  if (token) {
    sessionStorage.setItem(BNO_USER_TOKEN_STORAGE, token.toString());
  }

  const app_name = 'RideWithGps';
  const app_name_lowecase = app_name.toLowerCase();

  setTimeout(() => {
    if (action === 'authorization_url') {
      currentAction.value = ref(t('connecting_with', {app: app_name}));
      axios.get(API_APPS_ENDPOINT_V2 + '-by-value?value=' + app_name_lowecase ).then((response) => {
        const app = response.data.data;

        if (!app) {
          toast({
            message: t('app_config_not_found', {app: app_name}),
            type: 'is-danger',
            dismissible: true,
            duration: 3000
          });

          return false;
        }
        getAuthorizationUrl(app.url_social_request);
      })
      .catch((error) => {
        let message = t('error');
        if (error.response) {
          message = error.response.data;
        } else if (error.request) {
          message = error.request;
        } else {
          message = error.message;
        }

        toast({
          message: message,
          type: 'is-danger',
          dismissible: true,
          duration: 3000
        })
      });
    } else {

      if (code) {
        const bno_user_token = sessionStorage.getItem(BNO_USER_TOKEN_STORAGE);
        currentAction.value = t('saving_credentials_in', {app: app_name});

        axios.post(API_ENDPOINT_V2 + app_name_lowecase + '/token-credentials', {
          state: state,
          code: code
        }, {
          headers: { 'Authorization': 'Bearer ' + bno_user_token, 'ApiLang': locale.value }
        }).then(() => {

          currentAction.value = t('authentication_with_app_successful', {app: app_name});

          toast({
            message: t('authentication_with_app_successful_and_close_window', {app: app_name}),
            type: 'is-success',
            dismissible: true,
            duration: 4000
          });

          try {
            setInterval(() => {
              window.close();
            }, 2000);
          } catch (e){}

        })
        .catch((error) => {
          currentAction.value = '';
          toast({
            message: error.response,
            type: 'is-danger',
            dismissible: true,
            duration: 3000
          })
        });
      }

    }
  }, 2000);

  async function getAuthorizationUrl(url)
  {
    await axios.get(url, {headers: { 'Authorization': 'Bearer ' + token, 'ApiLang': locale.value }}).then((response) => {
      const data = response.data.data;
      if (data && data.hasOwnProperty('authorization_url')) {
        sessionStorage.setItem('temporary_credentials', JSON.stringify(data.temporary_credentials));
        window.location.href = data.authorization_url;
      } else {
        toast({
          message: t('error_contact_url_authorization_app', {app: app_name}),
          type: 'is-danger',
          dismissible: true,
          duration: 4000
        });
      }
    }).catch(() => {});
  }
</script>

<template>
  <SocialApp :current-action="currentAction" :app-name="app_name_lowecase" />
</template>
