import en from '@/assets/i18n/en';
import es from '@/assets/i18n/es';

import { createI18n } from 'vue-i18n';

const locale = window.navigator.language;

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    allowComposition: true, // you need to specify that!
    messages: {
        en: en,
        es: es
    },
    globalInjection: true,
    legacy: false,
    warnHtmlInMessage: false
});

export default i18n;
