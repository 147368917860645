<script setup>
  import { useMetaRoute, useTheme } from '@/composables';
  import { useI18n } from "vue-i18n";

  import { GOOGLE_FORM } from "@/services/variables.service";

  const { t, locale } = useI18n();
  const { themeCls } = useTheme();

  useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">

    <section id="charity" class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-align-items-center is-desktop">
            <div class="column has-text-centered mt-5">
              <h1 class="title">{{ t('we_organize_charity_competitions') }}</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
             <div class="block">
               <p class="feature">{{ t('we_organize_charity_competitions_description') }}</p>
               <p class="feature mt-4">{{ t('we_organize_charity_competitions_description_2') }}</p>
             </div>
              <div class="block has-text-centered">
                <a class="button is-rounded is-success" target="_blank" :href="GOOGLE_FORM[locale.substring(0, 2)] ?? GOOGLE_FORM['en']">
                  {{ t('request_information') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="influencer" :class="['container dashboard-content', themeCls]">
      <div class="columns mb-0 is-align-items-center is-desktop">
        <div class="column">
          <h1 class="title">{{ t('monetize_your_sport_activities') }}</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column pt-0 is-8 is-offset-2 has-text-left">
          <div class="block">
            <div class="feature" v-html="t('monetize_description')"></div>
          </div>
          <div class="block">
             <p class="feature"><font-awesome-icon :icon="['fa', 'circle-dot']" /> {{ t('monetize_description_2') }}</p>
          </div>
          <div class="block">
            <p class="feature"><font-awesome-icon :icon="['fa', 'circle-dot']" /> {{ t('monetize_description_3') }}</p>
          </div>
          <div class="content" v-html="t('monetize_description_4')"></div>
          <div class="block has-text-centered">
            <a class="button is-rounded is-success" target="_blank" :href="GOOGLE_FORM[locale.substring(0, 2)] ?? GOOGLE_FORM['en']">
              {{ t('request_information') }}
            </a>
          </div>
        </div>
      </div>
    </section>

    <section id="company" class="hero bg-color-light">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-align-items-center is-desktop">
            <div class="column has-text-centered is-full-mobile">
              <h1 class="title">{{ t('organize_competitions_for_your_company') }}</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="block">
                <p class="feature mb-3">
                 <font-awesome-icon :icon="['fa', 'circle-dot']" /> {{ t('organize_competitions_for_your_company_block.title_1')}}
                </p>
                <p class="feature">
                  {{ t('organize_competitions_for_your_company_block.text_1')}}
                </p>
              </div>
              <div class="content">
                <ul type="1">
                  <li>{{ t('organize_competitions_for_your_company_block.list_1') }}</li>
                  <li>{{ t('organize_competitions_for_your_company_block.list_2') }}</li>
                </ul>
              </div>
              <div class="block">
                <p class="feature mb-3">
                  <font-awesome-icon :icon="['fa', 'circle-dot']" /> {{ t('organize_competitions_for_your_company_block.title_2') }}
                </p>
              </div>
              <div class="content">
                <ul type="1">
                  <li>{{ t('organize_competitions_for_your_company_block.list_3') }}</li>
                  <li>{{ t('organize_competitions_for_your_company_block.list_4') }}</li>
                  <li>{{ t('organize_competitions_for_your_company_block.list_5') }}</li>
                </ul>
              </div>
              <div class="block has-text-centered">
                <a class="button is-rounded is-success" target="_blank" :href="GOOGLE_FORM[locale.substring(0, 2)] ?? GOOGLE_FORM['en']">
                  {{ t('request_information') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
