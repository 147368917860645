<script setup>
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-8 is-offset-2 has-text-left">
              <div class="column is-full-mobile">
                <h1 class="title">En el modo indoor están los siguientes deportes:</h1>
                <div class="block pl-5 pr-5">
                  <div class="feature mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Correr en cinta</span>
                  </div>
                  <div class="feature mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Andar en cinta</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Elíptica</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Remo en máquina</span>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Ciclismo</span>
                    <div class="content">
                      <ul type="1">
                        <li>Bicicleta de Spinning</li>
                        <li>Bicicleta inteligente</li>
                        <li>Bicicleta con rodillo inteligente</li>
                        <li>Handbike con rodillo inteligente</li>
                      </ul>
                    </div>
                  </div>
                  <div class="feature mt-5 mb-5">
                    <font-awesome-icon :icon="['fa', 'check']" /> <span>Natación en piscina (cubierta o no)</span>
                  </div>
                </div>
              </div>
              <div class="block pl-5 pr-5">
                <div class="feature mb-5">
                  Todos los deportes pueden ser configurados y adaptados para personas con discapacidad física.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
