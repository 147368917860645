<script setup>
  import { onMounted, ref } from 'vue';
  import { useMetaRoute } from '@/composables';
  import axios from "axios";
  import { API_ENDPOINT_V2 } from "@/services/variables.service";
  import { toast } from "bulma-toast";
  import { useI18n } from "vue-i18n";

  const { t, locale } = useI18n();

  useMetaRoute();

  const appAccounts = ref([]);
  const appAccountsAux = ref([]);
  const currentSegment = ref('segment-0');

  onMounted(async() => {
    await getAppAccounts();
  });

  async function getAppAccounts()
  {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    await axios.get(API_ENDPOINT_V2 + 'apps-by-group?group=sport', headers).then((response) => {
      appAccounts.value = response.data.data.data;
      appAccountsAux.value = appAccounts.value;
      console.log(appAccounts.value);
    }).catch(() => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }

  function segmentSportChanged(segmentIndex, tracker, isClear = false)
  {
    currentSegment.value = 'segment-' + segmentIndex;
    appAccountsAux.value = [];
    if (isClear) {
      appAccountsAux.value = appAccounts.value;
    } else {
      for (const i in appAccounts.value) {
        if (Object.keys(appAccounts.value[i].value).length > 0) {
          appAccountsAux.value.push(appAccounts.value[i]);
        }
      }
    }
  }

</script>
<template>
  <div class="view-wrapper-full">
    <section class="hero bg-color-light padding-top-100px">
      <div class="content mb-0 mt-5">
        <article class="panel">
          <p class="panel-heading">
            {{ t('how_to_configure_your_trackers') }}
          </p>
          <div class="panel-tabs p-2">
            <a :id="'segment-0'" :class="currentSegment === 'segment-0' ? 'is-active pl-4 pr-4' : 'pl-4 pr-4 has-text-gold'" @click="segmentSportChanged(0, null, true)">
              {{ t('alls') }}
            </a>
            <a :id="'segment-' + (index+1)"
               :class="(currentSegment === ('segment-' + (index+1))) ? 'is-active pl-4 pr-4' : 'pl-4 pr-4 has-text-gold'"
               v-for="(app, index) of appAccountsAux" :key="index" @click="segmentSportChanged(index+1, app)">
              {{ app?.title }}
            </a>
          </div>
          <div :class="(currentSegment === ('segment-' + (index+1)) || currentSegment === 'segment-0') ? 'panel-block is-active ' + (index%2===0 ? 'has-background-white' : 'has-background-light') : 'panel-block is-hidden'"  v-for="(app, index) of appAccounts" :key="index">
            <div class="container">
              <div class="columns">
                <div class="column is-3 column-title-app">
                  <h4><i class="fas fa-book mr-3" aria-hidden="true"></i>{{ app?.title }}</h4>
                </div>
                <div class="column is-9">
                  <div class="is-justify-content-center content" v-html="app?.description"></div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>
<style>
  .panel-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  .column-title-app {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .panel-tabs {
    overflow: auto;
  }

  @media all and (max-width: 1099px) {
    .panel-tabs a:first-child {
      margin-left: 40px !important;
    }
  }
</style>
