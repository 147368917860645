<script setup>
  import { ref, onMounted } from 'vue';
  import { useI18n } from "vue-i18n";
  import axios from 'axios';
  import bulmaCarousel from 'bulma-carousel';
  import { toast } from "bulma-toast";
  import { API_COMPETITIONS_CAMPAIGNS_ENDPOINT } from '@/services/variables.service.js';
  import CampaignCard from '@/components/CampaignCard.vue';

  const { t, locale } = useI18n();
  const campaigns = ref([]);

  if (bulmaCarousel) {
    bulmaCarousel.attach('#carousel-campaigns', {
      slidesToScroll: 1,
      slidesToShow: 4,
      loop: true,
      infinite: false
    });
  }

  onMounted(async() => {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    axios.get(API_COMPETITIONS_CAMPAIGNS_ENDPOINT + '?active=true', headers).then((response) => {
      campaigns.value = response.data.data;
    })
    .catch((error) => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  });

</script>

<template>
  <div v-if="campaigns.length" class="columns pt-5 pb-5">
    <div class="column is-8 is-offset-2 mt-5">
      <h1 class="title has-text-centered has-text-bno-black">
        {{ t('campaigns')}}
      </h1>
      <hr class="has-350px-width ml-auto mr-auto"/>
      <div id="featured-competitions" class="slider carousel-competitions">
        <div class="slider-container is-align-items-center">
          <div class="p-2" :class="'slider-item item-' + index" v-for="(campaign, index) of campaigns" :key="index">
            <router-link :to="{ name: 'Campaign', params:{id: campaign.id} }">
              <CampaignCard :campaign="campaign" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import 'bulma-carousel/dist/css/bulma-carousel.min.css';

.slider-item {
  max-width: 360px;
}
</style>
