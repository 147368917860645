<script setup>
  import CampaignCard from '@/components/CampaignCard.vue';
  import CompetitionCard from '@/components/CompetitionCard.vue';

  import { onMounted, ref } from 'vue';
  import { useMetaRoute } from '@/composables';
  import axios from "axios";
  import { API_ENDPOINT } from "@/services/variables.service";
  import { toast } from "bulma-toast";
  import { useI18n } from "vue-i18n";

  const { t, locale } = useI18n();

  useMetaRoute();

  const competitions = ref([]);
  const sportsValuesCheck = ref([]);
  const sports = ref([]);
  const listFeed = ref([]);
  const listFeedAux = ref([]);
  const currentSegment = ref('segment-0');

  onMounted(async() => {
    await getSports();
    await getLiveFeed();
  });

  async function getSports()
  {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    await axios.get(API_ENDPOINT + 'sports', headers).then((response) => {
      const s = response.data.data;
      // eslint-disable-next-line guard-for-in
      for (const i in s) {
        const value = s[i].value;
        if (sportsValuesCheck.value.indexOf(value) === -1) {
          sportsValuesCheck.value.push(value);
          sports.value.push(s[i]);
        }
      }
    }).catch(() => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }

  async function getLiveFeed()
  {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    await axios.get(API_ENDPOINT + 'live-feed', headers).then((response) => {
      listFeed.value = response.data.data;
      listFeedAux.value = listFeed.value;
      // eslint-disable-next-line guard-for-in
    }).catch(() => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }

  function segmentSportChanged(segmentIndex, sport, isClear = false)
  {
    currentSegment.value = 'segment-' + segmentIndex;
    listFeedAux.value = [];
    if (isClear) {
      listFeedAux.value = listFeed.value;
    } else {

      for (const i in listFeed.value) {
        if (Object.keys(listFeed.value[i].data).length > 0) {
          try {
            if (listFeed.value[i].data.sport.group_name === sport.group_name) {
              listFeedAux.value.push(listFeed.value[i]);
            }
          } catch (e) {}
        }
      }
    }
  }

</script>
<template>
  <div class="view-wrapper-full">
    <section class="hero bg-color-light padding-top-100px">
      <div class="content mb-0 mt-5">
        <div class="tabs is-centered is-boxed">
          <ul>
            <li :id="'segment-0'" :class="currentSegment === 'segment-0' ? 'is-active' : ''">
              <a @click="segmentSportChanged(0, null, true)">{{ t('alls') }}</a>
            </li>
            <li :id="'segment-' + (index+1)" :class="(currentSegment === ('segment-' + (index+1))) ? 'is-active' : ''" v-for="(sport, index) of sports" :key="index">
              <a v-if="!sport.parent_id" @click="segmentSportChanged(index+1, sport)">{{ sport?.translate }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="block p-4">
        <div class="columns is-multiline mb-5">
          <div class="column is-3" v-for="(feed, index) of listFeedAux" :key="index">
            <div class="block" v-if="feed?.data?.id">
              <router-link v-if="feed?.type === 'competition'" :to="{ name: 'Competition', params:{id: feed?.data?.id} }">
                <CompetitionCard :competition="feed?.data" />
              </router-link>
              <router-link v-if="feed?.type === 'campaign'" :to="{ name: 'Campaign', params:{id: feed?.data?.id} }">
                <CampaignCard :campaign="feed?.data" />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-if="listFeedAux.length === 0" class="columns mb-5">
        <div class="column is-8 is-offset-2">
          <h1 class="title has-text-centered has-text-bno-black mt-5 mb-5">
            {{ t('no_results')}}
          </h1>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
  @import 'bulma-carousel/dist/css/bulma-carousel.min.css';

  .slider-item {
    max-width: 360px;
  }
</style>
