<script setup>
  import { defineProps, ref } from 'vue';
  import { useI18n } from "vue-i18n";
  import { getSymbolCurrency } from "@/services/variables.service";

  const { t, locale } = useI18n();

  const showPrizes = ref(false);

  const props = defineProps({
    competition: Object
  });

</script>

<template>
  <div v-if="competition" class="card border-radius-25">
      <div class="card-content">
        <div class="columns has-text-centered is-mobile">
          <div class="column is-4">
            <p class="m-0">{{ t('sport') }}</p>
            <p class="m-0 has-text-weight-bold">
              {{ competition?.sport?.translate }}
            </p>
          </div>
          <div class="column is-4">
            <p class="m-0">{{ t('type') }}</p>
            <p class="m-0 has-text-weight-bold">
              {{ competition?.sport?.mode?.translate }}
            </p>
          </div>
          <div class="column is-4">
            <p class="m-0">{{ t('gender') }}</p>
            <p v-if="competition?.gender" class="m-0 has-text-weight-bold">
              {{ t(competition.gender) }}
            </p>
          </div>
        </div>

        <div class="columns has-text-centered is-mobile">
          <div class="column is-12">
            <p class="m-0 tag has-text-weight-bold">{{ t('target') }}</p>
            <div class="mt-3" v-html="competition?.short_description"></div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-12 has-text-centered">
            <button class="button small" @click="showPrizes = !showPrizes">
              <span v-if="!showPrizes">{{ t('see_prizes') }}</span>
              <span v-if="showPrizes">{{ t('hide_prizes') }}</span>
            </button>
          </div>
        </div>

        <div v-if="showPrizes" class="block mt-4 has-text-centered">
          <div class="columns is-mobile mb-0">
            <div class="column is-12 pb-0">
              <h3 class="has-text-centered has-text-weight-bold">{{ t('prizes') }}</h3>
              <hr class="mb-0 mt-3"/>
            </div>
          </div>

          <div v-if="competition?.calculatedPrizes?.monetary" class="block">
            <div v-if="competition?.calculatedPrizes?.distribution_monetary?.length === 0" class="columns is-mobile mb-0">
              <div class="column is-12 pb-0">
                <div class="tag mt-3">
                  {{ t('no_prizes') }}
                </div>
              </div>
            </div>

            <div v-else class="block">
              <div class="columns is-mobile has-text-centered mt-1" v-for="(prize, index) of competition?.calculatedPrizes?.distribution_monetary" :key="index">
                <div class="column is-6 is-offset-3">
                  <div class="columns is-mobile">
                    <div class="column is-3 pt-0 pb-0">
                      <h3 class="is-size-4">{{ index+1 }}º</h3>
                    </div>
                    <div class="column is-9 pt-0 pb-0">
                      <h3 class="is-size-4">{{ prize }}{{ getSymbolCurrency(competition?.inscription?.currency) }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="block">
            <div class="columns is-mobile has-text-centered mt-1" v-for="(prize, index) of competition?.calculatedPrizes?.distribution_no_monetary" :key="index">
              <div class="column is-6 is-offset-3">
                <div class="columns is-mobile">
                  <div class="column pt-0 pb-0 is-3">
                    <h3 class="is-size-4">{{ index+1 }}º</h3>
                  </div>
                  <div class="column pt-0 pb-0 has-text-left is-9">
                    <h4 class="is-size-5" v-html="prize"></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>
