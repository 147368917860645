<script setup>
  import { ref, onMounted } from "vue";
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";
  import { PLAY_STORE_URL, APPLE_IOS_STORE_URL } from '@/services/variables.service.js';
  import { useRoute, useRouter} from "vue-router";
  import { CreateCampaignBlock } from "@/components";
  import bulmaCarousel from 'bulma-carousel';

  const router = useRouter();
  const route = useRoute();

  const { t, locale } = useI18n();
  const current_locale = locale.value.substring(0, 2).includes('es') || locale.value.substring(0, 2).includes('en') ? ref(locale.value.substring(0, 2)) : 'en';

  useMetaRoute();

  if (document.referrer.indexOf('connect.garmin.com') > -1) {
    // ?oauth_token=d95ff393-4b36-46cf-a2d8-bc978dc5bfcd&oauth_verifier=30Hlaj8Q0k#/
    router.push('/garmin/redirect?oauth_token=' + route.query.oauth_token + '&oauth_verifier=' + route.query.oauth_verifier);
  }

  onMounted(() => {
    bulmaCarousel.attach('#carousel-trackers', {
      slidesToScroll: 1,
      slidesToShow: 7,
      infinite: true,
      pagination: false,
      breakpoints: [
        { changePoint: 480, slidesToShow: 3, slidesToScroll: 1 },
        { changePoint: 640, slidesToShow: 4, slidesToScroll: 1 },
        { changePoint: 768, slidesToShow: 5, slidesToScroll: 1 }
      ]
    });
  });
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero is-dark padding-top-120px" :style="{'background-image': 'url(' + require('@/assets/images/bg-asfalto.jpg') + ')', 'background-size': 'cover','background-position': 'center', 'background-attachment': 'fixed' }">
      <div class="hero-body pb-0">
        <div class="container has-text-centered">
          <div class="columns">
            <div class="column is-three-fifths is-offset-one-fifth">
              <div class="block mb-5">
                <h1 class="title has-text-bno-light">{{ t('bnosport_description_new') }}</h1>
              </div>
              <div class="block">
                <p class="is-size-5 has-text-bno-light has-text-weight-medium has-text-line-height-1-25 is-italic" v-html="t('bnosport_subtitle_04_2024')"></p>
              </div>
            </div>
          </div>
          <div class="block">
            <p class="feature has-text-weight-bold has-text-bno-light has-text-weight-medium">{{ t('available_in') }}</p>
          </div>
          <div class="columns">
            <div class="column is-half is-offset-one-quarter">
              <div class="columns is-flex is-centered">
                <div class="column has-text-right">
                  <a :href="APPLE_IOS_STORE_URL" target="_blank">
                    <figure>
                      <img :src="require('@/assets/images/app-stores/app-store.svg')" alt="Apple Store" style="max-width: 140px;min-width: 140px;"/>
                    </figure>
                  </a>
                </div>
                <div class="column has-text-left">
                  <a :href="PLAY_STORE_URL" target="_blank">
                    <figure>
                      <img :src="require('@/assets/images/app-stores/google-play.svg')" alt="Google Play Store" style="max-width: 140px;min-width: 140px;"/>
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-half is-offset-one-quarter">
              <figure class="image">
                <img :src="require('@/assets/images/home_hero_web.png')" alt="Hero app">
              </figure>
            </div>
          </div>

        </div>
      </div>
    </section>

    <div class="hero is-medium has-carousel">
      <div id="carousel-trackers" class="carousel">
        <div class="item-1">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/apple.png')" alt="Apple">
          </figure>
        </div>
        <div class="item-2">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/garmin.png')" alt="Garmin">
          </figure>
        </div>
        <div class="item-3">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/polar.png')" alt="Polar Flow">
          </figure>
        </div>
        <div class="item-4">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/suunto.png')" alt="Suunto">
          </figure>
        </div>
        <div class="item-5">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/coros.png')" alt="Coros">
          </figure>
        </div>
        <div class="item-6">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/fitbit.png')" alt="Fitbit">
          </figure>
        </div>
        <div class="item-7">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/wahoo.png')" alt="Wahoo">
          </figure>
        </div>
        <div class="item-8">
          <figure class="image">
            <img :src="require('@/assets/images/trackers/ridewithgps.png')" alt="Ride with gps">
          </figure>
        </div>
      </div>
    </div>

    <section class="hero pt-5 pb-5">
      <div class="container">
        <div class="block has-text-centered pt-2 pb-5">
          <h3 class="subtitle is-5 is-bold">
            <span class="text-gradient">{{ t('its_very_simple') }}</span>
          </h3>
          <h2 class="title is-3 is-bold"><span>{{ t('follow_the_steps') }}</span></h2>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <div class="feature">
              <font-awesome-icon class="icon is-medium" :icon="['fa', 'mobile-alt']" />
            </div>
            <div class="text-block p-2">
              <h3 class="title is-5">{{ t('install_the_app') }}</h3>
              <p class="feature" v-html="t('install_the_app_message')"></p>
            </div>
          </div>
          <div class="column process-block has-text-centered">
            <div class="feature">
              <font-awesome-icon class="icon is-medium" :icon="['fa', 'user-plus']" />
            </div>
            <div class="text-block p-2">
              <h3 class="title is-5">{{ t('build_your_profile') }}</h3>
              <p class="feature" v-html="t('build_your_profile_message')"></p>
            </div>
          </div>
          <div class="column process-block has-text-centered">
            <div class="feature">
              <font-awesome-icon class="icon is-medium" :icon="['fa', 'flag-checkered']" />
            </div>
            <div class="text-block p-2">
              <h3 class="title is-5">{{ t('choose_or_create_a_competition') }}</h3>
              <p class="feature" v-html="t('choose_or_create_a_competition_message')"></p>
            </div>
          </div>
          <div class="column has-text-centered" >
            <div class="feature">
              <font-awesome-icon class="icon is-medium" :icon="['fa', 'trophy']" />
            </div>
            <div class="text-block p-2">
              <h3 class="title is-5">{{ t('reach_success') }}</h3>
              <p class="feature" v-html="t('reach_success_message')"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero pt-5 pb-5 has-background-light">
      <div class="container">
        <div class="columns is-align-items-center is-desktop">
          <div class="column is-full-mobile">
            <div class="block p-4">
              <h3 class="pb-4 subtitle is-6 is-bold is-leading">
                <span class="text-gradient">{{ t('competitions_for_everyone') }}</span>
              </h3>
              <h2 class="title is-3 is-semi is-leading">{{ t('unlock_your_potential') }}</h2>
              <p class="paragraph rem-95 mb-4">
               {{ t('unlock_your_potential_message') }}
              </p>
              <div class="columns is-multiline b-columns-half-tablet-p">
                <div class="column is-6">
                  <div class="py-2">
                    <h3 class="title is-6 is-leading">
                      <span>{{ t('track_assigned') }}</span>
                    </h3>
                    <p class="feature">
                      {{ t('track_assigned_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2">
                    <h3 class="title is-6  is-leading">
                      <span>{{ t('slope') }}</span>
                    </h3>
                    <p class="feature">
                     {{ t('slope_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('distance') }}</span>
                    </h3>
                    <p class="feature">
                      {{ t('distance_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('time') }}</span>
                    </h3>
                    <p class="feature">
                      {{ t('time_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="py-2">
                    <font-awesome-icon :icon="['fa', 'info-circle']" />
                    <span class="has-text-gold">{{ t('combinations_all_of_them') }}</span>
                  </div>
                </div>
                <div class="column is-hidden-widescreen">
                  <div class="block">
                    <figure class="image">
                      <img class="is-flex mx-auto" :src="require('@/assets/images/unlock_potential_' + current_locale + '.png')" alt="Unlock Your Potential" style="max-width: 200px;">
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-full-mobile is-hidden-mobile">
            <div class="columns">
              <div class="column is-half is-offset-one-quarter">
                <div class="block">
                  <figure class="image">
                    <img :src="require('@/assets/images/unlock_potential_' + current_locale + '.png')" alt="Unlock Your Potential">
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero pt-5 pb-5 has-background-light">
      <div class="container">
        <div class="columns is-align-items-center is-desktop">
          <div class="column is-full-mobile is-hidden-mobile">
            <div class="columns">
              <div class="column is-half is-offset-one-quarter">
                <div class="block">
                  <figure class="image">
                    <img :src="require('@/assets/images/tracking_' + current_locale + '.png')" alt="Trackers">
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-full-mobile">
            <div class="block p-4">
              <h3 class="pb-4 subtitle is-6 is-bold is-leading">
                <span class="text-gradient">{{ t('tracking') }}</span>
              </h3>
              <h2 class="title is-3 is-semi is-leading">{{ t('synchronize_your_activity') }}</h2>
              <p class="paragraph rem-95 mb-4">
                {{ t('synchronize_your_activity_message') }}
              </p>
              <div class="columns is-multiline b-columns-half-tablet-p">
                <div class="column is-6">
                  <div class="py-2 medium:py-3">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('garmin_connect') }}</span>
                    </h3>
                    <p class="paragraph rem-85 max-w-3">
                      {{ t('garmin_connect_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2 medium:py-3">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('polar_flow') }}</span>
                    </h3>
                    <p class="paragraph rem-85 max-w-3">
                      {{ t('polar_flow_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2 medium:py-3">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('apple_health') }}</span>
                    </h3>
                    <p class="paragraph rem-85 max-w-3">
                      {{ t('apple_health_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="py-2 medium:py-3">
                    <h3 class="title is-6 is-semi is-leading">
                      <span>{{ t('files_gpx_tcx_fit') }}</span>
                    </h3>
                    <p class="paragraph rem-85 max-w-3">
                      {{ t('files_gpx_tcx_fit_text') }}
                    </p>
                  </div>
                </div>
                <div class="column is-hidden-widescreen">
                  <div class="block">
                    <figure class="image">
                      <img class="is-flex mx-auto" :src="require('@/assets/images/tracking_' + current_locale + '.png')" alt="Trackers" style="max-width: 200px;">
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero pt-5 pb-5">
      <div class="hero-body">
        <div class="container">
          <CreateCampaignBlock />
        </div>
      </div>

    </section>


  </div>
</template>
<style>
@import 'bulma-carousel/dist/css/bulma-carousel.min.css';
.slider {
  overflow: hidden;
}
</style>
