import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import {
    faSun,
    faMoon,
    faCheck,
    faInfoCircle,
    faAngleDoubleUp,
    faExternalLinkAlt,
    faExclamationCircle,
    faMale,
    faFemale,
    faClock,
    faMapLocationDot,
    faMapLocation,
    faRoute,
    faArrowTrendDown,
    faArrowTrendUp,
    faLocationPin,
    faRightToBracket,
    faBriefcaseMedical,
    faGift,
    faTrophy,
    faAngleDown,
    faCircleDot,
    faWheelchairMove,
    faBars,
    faClose,
    faEnvelope,
    faArrowRightToBracket,
    faArrowRightFromBracket,
    faMobile,
    faMobilePhone,
    faMobileAlt,
    faUserPlus,
    faMap,
    faFlagCheckered
} from '@fortawesome/free-solid-svg-icons';


library.add(
    faSun,
    faMoon,
    faCheck,
    faInfoCircle,
    faAngleDoubleUp,
    faExternalLinkAlt,
    faExclamationCircle,
    faMale,
    faFemale,
    faClock,
    faMapLocationDot,
    faRoute,
    faMapLocation,
    faArrowTrendDown,
    faArrowTrendUp,
    faLocationPin,
    faRightToBracket,
    faBriefcaseMedical,
    faGift,
    faTrophy,
    faAngleDown,
    faCircleDot,
    faWheelchairMove,
    faBars,
    faClose,
    faEnvelope,
    faArrowRightToBracket,
    faArrowRightFromBracket,
    faMobile,
    faMobilePhone,
    faMobileAlt,
    faUserPlus,
    faMap,
    faFlagCheckered
);
dom.watch();
config.autoAddCss = false;
