<script setup>
import { onMounted, ref, computed } from 'vue';
import { useI18n } from "vue-i18n";
import { Menu } from '@/components';
import { useRouter } from "vue-router";

const { t } = useI18n();

const isHiddenSidebar = ref(true);
const router = useRouter();
// const componentName = ref('default');

const componentName = computed(() => {
   return router.currentRoute.value.name ?? 'default';
})

onMounted(() => {


  let navbar = document.getElementById("navbar");
  let scrollTop = 0;
  let previous = 0;
  /*
  const is_hidden = 'is-hidden-transition';
  window.addEventListener("scroll", function (e) {
    if (navbar) {
      scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

      if (previous < scrollTop) {
        navbar.classList.add(is_hidden);
      } else {
        navbar.classList.remove(is_hidden);
      }

      previous = scrollTop;
    }
  });

   */
})

</script>

<template>
  <div :class="'navbar-content navbar-' + componentName">
    <nav id="navbar" :class="'navbar p-0'" role="navigation" aria-label="main navigation">
      <div class="navbar-wrapper">
        <div class="brand-wrapper">
          <div class="logo-navbar">
            <router-link :to="{name: 'Home'}" class="is-flex is-align-items-center">
              <img src="@/assets/images/logos/bnosport/logo-sports.svg" alt="Logo BNOSport">
              <span class="logo-text ml-2 has-text-bno-black has-text-weight-semibold">Be Number One Sport</span>
            </router-link>
          </div>
        </div>
        <div class="navbar-routes is-hidden-touch">
          <Menu />
        </div>
        <div class="is-hidden-desktop btn-open-sidebar">
          <p class="buttons">
            <button class="button mb-0" @click="isHiddenSidebar = !isHiddenSidebar">
              <span class="icon is-small">
                <font-awesome-icon v-if="isHiddenSidebar" classsss="fa-2xl" :icon="['fa', 'bars']" />
                <font-awesome-icon v-else class="fa-2xl" :icon="['fa', 'close']" />
              </span>
            </button>
          </p>
        </div>
      </div>
    </nav>
    <div class="is-hidden-desktop">
      <aside class="menu sidebar-menu p-5" :class="isHiddenSidebar ? 'is-hidden': ''">
        <p class="menu-label">
          General
        </p>
        <ul class="menu-list">
          <li>
            <router-link :to="{name: 'Home'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('home') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'Apps'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('apps') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'Competitions'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('competitions') }}</span>
            </router-link>
          </li>
          <li>
            <router-link exact-active-class="null" :to="{name: 'Home', hash: '#organizers'}" v-scroll-to="'#organizers'" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('organizers') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'Trackers'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('trackers') }}</span>
            </router-link>
          </li>
          <li>
            <a>{{ t('modes') }}</a>
            <ul>
              <li>
                <router-link :to="{name: 'Outdoor'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
                  <span>{{ t('outdoor') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'Indoor'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
                  <span>{{ t('indoor') }}</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link :to="{name: 'Contact'}" class="navbar-item" @click="isHiddenSidebar = !isHiddenSidebar">
              <span>{{ t('contact') }}</span>
            </router-link>
          </li>
        </ul>
      </aside>
    </div>
  </div>
</template>
