<script setup>
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from "vue-router";

  const router = useRouter();
  const route = useRoute();

  const { t } = useI18n();
</script>

<template>
  <router-link :to="{name: 'Apps'}" class="navbar-item">
    <span>{{ t('apps') }}</span>
  </router-link>
  <router-link :to="{name: 'Competitions'}" class="navbar-item">
    <span>{{ t('competitions') }}</span>
  </router-link>
  <router-link exact-active-class="null" :to="{name: 'Home', hash: '#organizers'}" v-scroll-to="'#organizers'" class="navbar-item">
    <span>{{ t('organizers') }}</span>
  </router-link>
  <router-link :to="{name: 'Trackers'}" class="navbar-item">
    <span>{{ t('trackers') }}</span>
  </router-link>
  <router-link :to="{name: 'Contact'}" class="navbar-item">
    <span>{{ t('contact') }}</span>
  </router-link>
</template>
