<script setup>
  import { defineProps } from 'vue';
  import { useI18n } from "vue-i18n";
  const { t, locale } = useI18n();
  import { getSymbolCurrency } from "@/services/variables.service";

  const props = defineProps({
    competition: Object
  });

</script>

<template>
  <div v-if="competition" class="card is-clickable border-bottom-radius-25">
      <div class="card-image">
        <figure class="image is-16by9  is-covered">
          <img v-if="competition?.image?.path" :src="competition?.image?.path" :alt="competition?.image?.path">
        </figure>
      </div>
      <div class="card-content has-text-centered">
        <span v-if="competition?.extra?.charity" class="tag charity-badge hast-text-centered mb-3">
          {{ $t('charity_competition') }}
        </span>
        <div class="columns is-mobile">
          <div class="column is-full has-text-centered is-12">
            <div v-if="competition?.name">
              <p class="mb-0">{{ competition?.name }}</p>
            </div>
            <div v-if="!competition?.name && competition?.hashtag">
              <p class="mb-0">{{ competition?.hashtag }}</p>
            </div>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-12">
            <p class="font-bold is-size-6 has-text-weight-bold">
              {{ competition?.sport?.translate_by_lang[locale ?? 'es'] }}
              {{ competition?.sport?.mode?.translate_by_lang[locale ?? 'es'] }}
            </p>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-12 has-text-weight-bold">
            <ul v-if="!competition.file_track_id" class="ion-no-padding">
              <li>
                <p class="is-size-6">
                  {{ competition?.time_distance }}
                  {{ $t(competition?.competition_by + '_units.' + competition?.time_distance_unit) }}
                </p>
                <p class="m-0">-</p>
              </li>
              <li>
                <p class="is-size-6">
                  {{ competition?.type?.translate_by_lang[locale ?? 'es'] }}
                </p>
              </li>
            </ul>
            <ul v-else class="ion-no-padding">
              <li>
                <p class="is-size-7" v-html="competition?.short_description_by_lang[locale ?? 'es']"></p>
              </li>
            </ul>

          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-12">
            <div class="block has-text-centered">
              <div v-if="competition?.gender === 'mens' || competition?.gender === 'men'">
                <font-awesome-icon class="icon is-medium" :icon="['fa', 'male']" />
              </div>
              <div v-if="competition?.gender === 'womens' || competition?.gender === 'women'">
                <font-awesome-icon class="icon is-medium" :icon="['fa', 'female']" />
              </div>
              <div v-if="competition?.gender === 'alls'">
                <font-awesome-icon class="icon is-medium" :icon="['fa', 'female']" />
                <font-awesome-icon class="icon is-medium" :icon="['fa', 'male']" />
              </div>

              <div v-if="competition?.extra?.disabled_people" class="mt-3 mb-5">
                <font-awesome-icon class="icon" :icon="['fa', 'wheelchair-move']" />
              </div>

              <div v-if="!competition?.file_track?.file?.path" class="mt-3">
                <font-awesome-icon v-if="competition.competition_by === 'time'" class="icon is-medium" :icon="['fa', 'clock']" />
                <font-awesome-icon v-if="competition.competition_by === 'distance'" class="icon is-medium" :icon="['fa', 'map-location']" />
                <font-awesome-icon v-if="competition.competition_by === 'slope'" class="icon is-medium" :icon="['fa', 'arrow-trend-up']" />
                <font-awesome-icon v-if="competition.competition_by === 'negative_slope'" class="icon is-medium" :icon="['fa', 'arrow-trend-down']" />
                <span v-if="competition.competition_by === 'accumulated_slope'">
                  <font-awesome-icon class="icon is-medium mr-1" :icon="['fa', 'arrow-trend-up']" />
                  <font-awesome-icon class="icon is-medium" :icon="['fa', 'arrow-trend-down']" />
                </span>
              </div>
              <div v-else class="mt-3">
                <font-awesome-icon class="icon is-medium" :icon="['fa', 'route']" />
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile mb-3">
          <div class="column p-0 has-text-centered is-6">
              <font-awesome-icon class="icon" :icon="['fa', 'right-to-bracket']" />
              <span class="ml-2 is-size-6" v-if="competition?.inscription?.amount > 0">
                 {{ competition?.inscription?.amount }}
                 {{ getSymbolCurrency(competition?.inscription?.currency) }}
              </span>
              <span v-else class="ml-2 is-size-6">
                {{ t('free') }}
              </span>
          </div>
          <div class="column p-0 has-text-centered is-6">
            <font-awesome-icon class="icon" :icon="['fa', 'trophy']" />
            <font-awesome-icon v-if="!competition?.calculatedPrizes?.monetary" class="icon ml-2" :icon="['fa', 'gift']" />
            <span class="ml-2 is-size-6" v-if="!competition?.extra?.charity">
              <span v-if="competition?.calculatedPrizes?.total > 0">
                {{ competition?.calculatedPrizes?.total }}
                {{ getSymbolCurrency(competition?.inscription?.currency) }}
              </span>
              <span v-if="competition?.calculatedPrizes?.monetary && (competition?.calculatedPrizes?.distribution_monetary?.length === 0 || competition?.calculatedPrizes?.total === 0)">
                {{ t('no_prizes') }}
              </span>
            </span>
            <span class="ml-2 is-size-6" v-else>
              <font-awesome-icon  class="icon" :icon="['fa', 'briefcase-medical']" />
            </span>
          </div>
        </div>
        <div class="columns is-mobile mt-3">
          <div class="column is-6 has-text-centered">
            <p class="m-0 is-size-7">{{ t('start_date') }}</p>
            <span class="tag">
              {{ $filters.formatDate(competition?.start_date) }}
            </span>
          </div>
          <div class="column is-6 has-text-centered">
            <p class="m-0 is-size-7">{{ t('end_date') }}</p>
            <span class="tag">
              {{ $filters.formatDate(competition?.end_date) }}
            </span>
          </div>
        </div>
      </div>
    </div>
</template>
