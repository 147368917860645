<script setup>
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { API_ENDPOINT_V2 } from "@/services/variables.service";
import { useRoute, useRouter } from "vue-router";
import { StorageService } from "@/services/storage.service";
import { toast } from "bulma-toast";
import useEventBus from "../composables/useEventBus";

const { t, locale } = useI18n();
const route = useRoute();
const eventBus = useEventBus();
const router = useRouter();

const callbackGoogleLogin = (response) => {
  if (response && response.hasOwnProperty('access_token')) {
    postSocialite(response.access_token, 'google');
  }
}

async function postSocialite(accessToken, provider = 'google')
{
  const headers = {
    headers: {
      'ApiLang': locale.value
    }
  };

  const data = {
    accessToken: accessToken
  };

  await axios.post(API_ENDPOINT_V2 + 'socialite/' + provider, data, headers).then((response) => {
    const data = response.data.data;

    StorageService.setUserData(data);
    StorageService.setAuthToken(response.data.token);
    StorageService.setLogin();

    eventBus.emit('login.success', data);

    setTimeout(() => {
      router.push('/');
      return false;
    }, 1000);

  }).catch((error) => {
    const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');
    toast({
      message: message,
      type: 'is-danger',
      dismissible: true,
      duration: 5000
    })
  });
}
</script>

<template>
  <GoogleLogin :callback="callbackGoogleLogin" auto-login popup-type="TOKEN">
    <button class="button is-rounded has-background-google is-fullwidth">
        <span class="icon mr-3">
          <font-awesome-icon class="icon" :icon="faGoogle" />
        </span>
      {{ t('multilogin.google')}}
    </button>
  </GoogleLogin>

</template>
