<script setup>
import { useMetaRoute } from '@/composables';
import { onMounted, ref } from 'vue';
import { useI18n } from "vue-i18n";
import { API_ENDPOINT } from '@/services/variables.service.js';
import axios from 'axios';
const { t, locale } = useI18n();
const privacy = ref([]);
import { toast } from 'bulma-toast';

useMetaRoute();

onMounted(async() => {
  const headers = {
    headers: {
      'ApiLang': locale.value
    }
  };
  await axios.get(API_ENDPOINT + 'legal/privacy-policies', headers).then((response) => {
    privacy.value = response.data.data;
  }).catch((error) => {
    toast({
      message: t('error'),
      type: 'is-danger',
      dismissible: true,
      duration: 3000
    })
  });
});
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero is-dark padding-top-120px">
      <div class="hero-body">
        <div class="container">
          <div class="is-flex is-horizontal-center">
            <figure class="image is-132x132 bg-white border-radius-8">
              <img class="img-fluid" src="@/assets/images/logos/bnosport/logo-sports.svg" alt="Logo BNOSport">
            </figure>
          </div>
          <div class="block mt-4 has-text-centered">
            <p class="title m-0">{{ t('title') }}</p>
          </div>
          <hr>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="card border-bottom-radius-25">
                <div class="card-content" v-html="privacy"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
