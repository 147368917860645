<script setup>
  import { Alert } from '@/components';
  import { isUrl } from '@/services/variables.service.js';
  import { onMounted, ref } from 'vue';
  import axios from "axios";
  import { toast } from "bulma-toast";
  import { useI18n } from "vue-i18n";
  import { useRoute } from 'vue-router';

  let alertSuccess = ref(false);
  let message = ref('');

  const { t, locale } = useI18n();
  const route = useRoute();
  const u = route.query.u;

  onMounted(async() => {
    await getVerify();
  });

  async function getVerify()
  {
    const url = atob(u).replace('http://','https://');

    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };

    if (isUrl(url)) {
      axios.get(url, headers).then((data) => {
        if (data.status === 202) {
          message.value = data.data.message;
          alertSuccess.value = true;
        }
      })
      .catch(() => {
        toast({
          message: t('error'),
          type: 'is-danger',
          dismissible: true,
          duration: 5000
        })
      });
    }
  }
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero padding-top-100px">
      <div class="hero-body">
        <div class="container has-text-centered">
          <div class="columns">
            <div class="column is-three-fifths is-offset-one-fifth">
              <div class="block">
                <p class="subtitle">{{ t('verification_title') }}</p>
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div v-if="alertSuccess" class="column is-12 has-text-centered mt-4">
              <Alert :title="message" sub-title=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.notification-tile {
  padding-top: 2rem !important;
}
</style>
