<script setup>
import { useMetaRoute } from '@/composables';
import { useI18n } from "vue-i18n";
import { Alert } from '@/components';
const { t } = useI18n();

useMetaRoute();
</script>

<template>
  <div class="view-wrapper-full">
    <section class="hero padding-top-120px pb-5" :style="{'background-image': 'url(' + require('@/assets/images/bg-home5.jpg') + ')', 'background-size': 'cover','background-position': 'center', 'background-attachment': 'fixed' }">
      <div class="is-flex is-horizontal-center">
        <figure class="image is-132x132 bg-white border-radius-8">
          <img class="img-fluid" src="@/assets/images/logos/bnosport/logo-sports.svg" alt="Logo BNOSport">
        </figure>
      </div>
      <div class="block mt-4 has-text-centered">
        <p class="title m-0 has-text-white">{{ t('title') }}</p>
      </div>
    </section>
    <section class="mb-5">
      <Alert
          :title="t('create_campaign')"
          class-name="is-success"
          icon-name="exclamation-circle"
          :sub-title="t('create_campaign_body_message')"
      />
    </section>
  </div>
</template>
