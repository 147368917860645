<script setup>
  import { Alert } from '@/components';
  import { isUrl } from '@/services/variables.service.js';
  import { onMounted, ref, computed } from 'vue';
  import axios from "axios";
  import { toast } from "bulma-toast";
  import { useMetaRoute } from '@/composables';
  import { useI18n } from "vue-i18n";
  import { useRoute } from 'vue-router';

  let alertSuccess = ref(false);
  let message = ref('');

  const { t, locale } = useI18n();
  const route = useRoute();
  const u = route.query.u;

  let password = ref(null);
  let repassword = ref(null);
  let typeInputPassword = ref('password');

  useMetaRoute();

  const matchPasswords = computed(() => password.value === repassword.value);

  function postResetPassword(event)
  {
    event.preventDefault();

    const url = atob(u).replace('http://','https://');

    if (isUrl(url)) {
      axios.post(url, { password: password.value }).then((data) => {
        if (data.data.hasOwnProperty('message')) {
          message.value = data.data.message;
        }

        if (data.data.hasOwnProperty('error')) {
          message.value = data.data.error;
        }

        if (data.data.hasOwnProperty('errors')) {
          message.value = data.data.errors;
        }
        alertSuccess.value = true;
        password.value = null;
        repassword.value = null;
      })
      .catch(() => {
        toast({
          message: t('error'),
          type: 'is-danger',
          dismissible: true,
          duration: 3000
        })
      });
    }
  }

</script>

<template>
  <div class="view-wrapper-full">

    <section id="username" class="hero bg-color-light padding-top-100px">
      <div class="hero-body">
        <div class="container">
          <div class="columns pb-5">
            <div class="column is-6 is-offset-3">
              <div class="card border-radius-25">
                <div class="card-header">
                  <div class="container p-4">
                    <p class="subtitle has-text-centered">{{ t('password_reset') }}</p>
                  </div>
                </div>
                <div class="card-content">

                  <div v-if="alertSuccess" class="columns is-mobile">
                    <div  class="column is-12 has-text-centered mt-4">
                      <Alert :title="message" sub-title=""/>
                    </div>
                  </div>

                  <div v-else class="columns is-mobile">
                    <div class="column is-three-fifths is-offset-one-fifth">
                      <form>
                        <div class="block has-text-centered mt-4">
                          <input required :minlength="8" v-model="password" class="input" id="password" :type="typeInputPassword" :placeholder="t('enter_new_password')" autocomplete="new-password">
                        </div>
                        <div class="block has-text-centered mt-4">
                          <input required :minlength="8" v-model="repassword" class="input" id="repassword" :type="typeInputPassword" :placeholder="t('enter_new_repassword')" autocomplete="new-password">
                        </div>

                        <div class="block has-text-centered mt-4">
                          <button :disabled="!matchPasswords" type="submit" @click="postResetPassword($event)" class="button has-background-green has-text-white">
                            {{ t('reset') }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<style>
.notification-tile {
  padding-top: 2rem !important;
}
</style>
