import {
  Home, Competitions, Competition,
  Monetize, Apps, Contact,
  Terms, PrivacyPolicy, NotFound,
  Plans, Verify, PasswordReset,
  Deposit, CreateCampaign, Campaigns,
  Campaign, Indoor, Outdoor,
  Login, Trackers,
  Garmin, Polar, Fitbit, Coros, Wahoo, Suunto, RideWithGps
} from '@/views';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { trackRouter } from "vue-gtag-next";

import {
  HOME_PAGE_META, COMPETITIONS_PAGE_META,
  MONETIZE_PAGE_META, APPS_PAGE_META,
  CONTACT_PAGE_META, TERMS_PAGE_META,
  PLANS_PAGE_META, PRIVACY_POLICY_PAGE_META,
  CREATE_CAMPAIGN_PAGE_META, CAMPAIGNS_PAGE_META,
  INDOOR_MODE_PAGE_META, OUTDOOR_MODE_PAGE_META
} from "@/config/meta-routes.config";

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: HOME_PAGE_META
  },
  {
    path: '/competitions',
    name: 'Competitions',
    component: Competitions,
    meta: COMPETITIONS_PAGE_META,
  },
  {
    path: '/competitions/:id',
    name: 'Competition',
    component: Competition,
    props: true,
    meta: COMPETITIONS_PAGE_META,
  },
  {
    path: '/create-campaign',
    name: 'CreateCampaign',
    component: CreateCampaign,
    meta: CREATE_CAMPAIGN_PAGE_META,
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: CAMPAIGNS_PAGE_META,
  },
  {
    path: '/campaigns/:id',
    name: 'Campaign',
    component: Campaign,
    meta: CAMPAIGNS_PAGE_META,
  },
  {
    path: '/monetize',
    name: 'Monetize',
    component: Monetize,
    meta: MONETIZE_PAGE_META,
  },
  {
    path: '/apps',
    name: 'Apps',
    component: Apps,
    meta: APPS_PAGE_META,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: CONTACT_PAGE_META,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: TERMS_PAGE_META,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: PRIVACY_POLICY_PAGE_META,
  },
  {
    path: '/plans',
    name: 'plans',
    component: Plans,
    meta: PLANS_PAGE_META,
  },
  {
    path: '/indoor',
    name: 'Indoor',
    component: Indoor,
    meta: INDOOR_MODE_PAGE_META,
  },
  {
    path: '/outdoor',
    name: 'Outdoor',
    component: Outdoor,
    meta: OUTDOOR_MODE_PAGE_META,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit
  },
  {
    path: '/garmin/redirect',
    name: 'Garmin',
    component: Garmin
  },
  {
    path: '/polar/redirect',
    name: 'Polar',
    component: Polar
  },
  {
    path: '/fitbit/redirect',
    name: 'Fitbit',
    component: Fitbit
  },
  {
    path: '/coros/redirect',
    name: 'Coros',
    component: Coros
  },
  {
    path: '/wahoo/redirect',
    name: 'Wahoo',
    component: Wahoo
  },
  {
    path: '/suunto/redirect',
    name: 'Suunto',
    component: Suunto
  },
  {
    path: '/ridewithgps/redirect',
    name: 'RideWithGps',
    component: RideWithGps
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/trackers',
    name: 'Trackers',
    component: Trackers
  },
  {
    path: '/404',
    name: 'NotFound404',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

function scrollBehavior(to, from, savedPosition) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (to.hash) {
        resolve({ selector: to.hash, el: to.hash, behavior: 'smooth' });
      }
      resolve({ left: 0, top: 0, behavior: 'smooth' });
    }, 800);
  });
}

// Create new instance of vue-router
const router = createRouter({
  routes,
  scrollBehavior,
  linkExactActiveClass: 'is-active',
  //history: (process.env.NODE_ENV !== "production") ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL)
  history: createWebHistory(process.env.BASE_URL)
});

trackRouter(router);

export default router;
