<script setup>
import { useReCaptcha } from 'vue-recaptcha-v3';
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { API_ENDPOINT } from "@/services/variables.service";
import { useRoute, useRouter } from "vue-router";
import { StorageService } from "@/services/storage.service";
import { toast } from "bulma-toast";
import useEventBus from "@/composables/useEventBus";

const { t, locale } = useI18n();
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const router = useRouter();

const route = useRoute();
const eventBus = useEventBus();

let email = ref(null);
let password = ref(null);

function showLoginForm()
{
    const login_form_content = document.getElementById('login_form_content');

    if (login_form_content.classList.contains('is-hidden')) {
      login_form_content.classList.remove('is-hidden');
    } else {
      login_form_content.classList.add('is-hidden');
    }
}

function login(event)
{
  event.preventDefault();

  recaptchaLoaded().then((loaded) => {
    // loaded true or false
    executeRecaptcha('login').then((token) => {

      axios.post(API_ENDPOINT + 'login', { email: this.email, password: this.password }).then((response) => {
        if (response.data.status !== 202) {
          const message = response.data.hasOwnProperty('errors') ? response.data.errors : (response.data.hasOwnProperty('data') ? response.data.data : response.data.message);
          toast({
            message: message,
            type: 'is-danger',
            dismissible: true,
            duration: 3000
          })
          return false;
        }

        const data = response.data.data;

        StorageService.setUserData(data);
        StorageService.setAuthToken(response.data.token);
        StorageService.setLogin();

        eventBus.emit('login.success', data);

        this.email = null;
        this.password = null;

        setTimeout(() => {
          router.push('/');
          return false;
        }, 1000);

      })
      .catch((error) => {
        const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');
        toast({
          message: message,
          type: 'is-danger',
          dismissible: true,
          duration: 5000
        })
      });


    }).catch((error) => {
      const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');
      toast({
        message: message,
        type: 'is-danger',
        dismissible: true,
        duration: 5000
      })
    })
  }).catch((error) => {
    const message = error.hasOwnProperty('response') && error.response.hasOwnProperty('message') ?  error.response.message : t('error');
    toast({
      message: message,
      type: 'is-danger',
      dismissible: true,
      duration: 5000
    })
  });

}

</script>

<template>
  <button class="button is-rounded has-background-light is-fullwidth mb-5" @click="showLoginForm()">
    <span class="icon mr-3">
      <font-awesome-icon class="icon" :icon="['fa', 'envelope']" />
    </span>
    {{ t('multilogin.email')}}
  </button>

  <div class="columns">
    <div class="column p-0">
      <div id="login_form_content" class="is-hidden block mt-5">
        <form class="box" @submit.prevent="login($event)">
          <div class="field">
            <label class="label has-text-left">{{ t('email') }}</label>
            <div class="control">
              <input v-model="email" class="input" type="email" placeholder="e.g. alex@example.com" required autocomplete="off">
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{ t('password') }}</label>
            <div class="control">
              <input v-model="password" class="input" type="password" placeholder="********" required autocomplete="off">
            </div>
          </div>
          <button class="button has-background-green mt-3">{{ t('accept') }}</button>
        </form>
      </div>
    </div>
  </div>
</template>
