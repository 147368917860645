export class StorageService
{
    static LOGIN = 'login';
    static USER_DATA = 'user_data';
    static AUTH_TOKEN = 'auth_token';
    static TOKEN = 'bno_user_token';

    static setLogin()
    {
        localStorage.setItem(this.LOGIN, 'true');
    }

    static isLogged()
    {
        return localStorage.getItem(this.LOGIN) === 'true';
    }

    static setLogout()
    {
        localStorage.setItem(this.LOGIN, 'false');
        this.removeUserData();
        this.removeAuthToken();
    }

    static setUserData(userData)
    {
        localStorage.setItem(this.USER_DATA, userData);
    }

    static getUserData()
    {
        return localStorage.getItem(this.USER_DATA);
    }

    static removeUserData()
    {
        localStorage.removeItem(this.USER_DATA);
    }

    static setAuthToken(token)
    {
        localStorage.setItem(this.AUTH_TOKEN, token);
        localStorage.setItem(this.TOKEN, token);
    }

    static getAuthToken()
    {
        return localStorage.getItem(this.AUTH_TOKEN);
    }

    static removeAuthToken()
    {
        localStorage.removeItem(this.AUTH_TOKEN);
        localStorage.removeItem(this.TOKEN);
    }


}
