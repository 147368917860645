<script setup>
  import { defineProps, ref, onMounted } from 'vue';
  import axios from 'axios';
  import { useI18n } from "vue-i18n";
  import { toast } from "bulma-toast";
  import { useRoute } from 'vue-router';

  const { t, locale } = useI18n();
  const route = useRoute();

  const direct_download = route.query.direct_download;

  const props = defineProps({
    competition: Object
  });

  let disableBtn = ref(false);


  onMounted(async() => {
    if (direct_download) {
      await downloadFileGpx();
    }
  });

  async function forceFileDownload(response) {
    const url = (window.URL || window['webkitURL']).createObjectURL(new Blob([response.data], { type: 'application/octet-stream'}));
    const link = document.createElement('a');
    const fileName = props.competition.file_track.file.path;
    const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
    link.href = url;
    link.setAttribute('download', props.competition.id + '.' + extension );
    document.body.appendChild(link);
    link.click();
    link.remove();

    disableBtn.value = false;
  }

  async function downloadFileGpx()
  {
    disableBtn.value = true;
    axios.get(props.competition.file_track.file.path, {
      responseType: 'arraybuffer'
    }).then((response) => {
      setTimeout(() => {
        forceFileDownload(response);
      }, 1000);
    })
    .catch(() => {
      disableBtn.value = false;
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }
</script>

<template>
  <div v-if="competition" class="card border-radius-25">
      <div class="card-content">
        <div v-if="competition?.file_track?.data?.original?.nominatim?.display_name" class="columns has-text-centered">
          <div class="column">
            {{ competition?.file_track?.data?.original?.nominatim?.display_name }}
          </div>
        </div>
        <div class="columns has-text-centered">
          <div class="column">
            <figure class="image is-3by1">
              <img class="border-top-radius-10" v-if="competition?.file_track?.static_map?.path" :src="competition?.file_track?.static_map?.path" :alt="competition?.file_track?.static_map?.path">
            </figure>
          </div>
        </div>
        <div class="columns">
          <div class="column pt-0">
            <button :disabled="disableBtn" class="button is-fullwidth border-bottom-radius-10 has-background-gold has-text-white" @click="downloadFileGpx()">
              {{ t('download_track') }}
            </button>
          </div>
        </div>

        <div v-if="competition?.file_track?.extension" class="columns">
          <div class="column pt-0">
            <div class="block has-text-centered" v-html="t('file_with_extension', {extension: competition?.file_track?.extension})"></div>
          </div>
        </div>

      </div>
    </div>
</template>
