<script setup>
  import CompetitionCard from '@/components/CompetitionCard.vue';
  import CompetitionInfo from '@/components/CompetitionInfo.vue';
  import CompetitionFileTrack from '@/components/CompetitionFileTrack.vue';
  import { onMounted, ref } from 'vue';
  import { useMetaRoute } from '@/composables';
  import axios from "axios";
  import { API_ENDPOINT } from "@/services/variables.service";
  import { toast } from "bulma-toast";
  import { useI18n } from "vue-i18n";
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const { t, locale } = useI18n();
  const competitionId = route.params.id; // read parameter id (it is reactive)

  useMetaRoute();

  const competition = ref([]);

  onMounted(async() => {
    await getCompetition();
  });

  async function getCompetition()
  {
    const headers = {
      headers: {
        'ApiLang': locale.value
      }
    };
    await axios.get(API_ENDPOINT + 'competitions/' + competitionId, headers).then((response) => {
      competition.value = response.data.data;
    }).catch(() => {
      toast({
        message: t('error'),
        type: 'is-danger',
        dismissible: true,
        duration: 3000
      })
    });
  }

</script>
<template>
  <div class="view-wrapper-full bg-color-light">
    <section class="hero">
      <div class="hero-body pt-0">
        <div class="container">
          <div v-if="competition" class="columns pb-5">
            <div class="column is-6 is-offset-3">
              <CompetitionCard :competition="competition" />

              <div class="block mt-5">
                <CompetitionInfo :competition="competition" />
              </div>

              <div v-if="competition?.file_track_id" class="block mt-5">
                <CompetitionFileTrack :competition="competition" />
              </div>


              <div v-if="competition && competition?.current_competition_by?.extra_minimum_units_translate" class="block mt-5">
                  <div class="notification is-warning has-text-centered border-radius-25 has-text-weight-bold" v-html="competition?.current_competition_by?.extra_minimum_units_translate"></div>
              </div>

              <div class="block mt-5">
                <div class="notification is-primary has-text-centered border-radius-25 has-text-weight-bold">
                  <router-link :to="{ name:'Apps' }">
                    {{ t('register_competition_from_app') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
